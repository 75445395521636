.balloon {
    &__close {
        border: $border-width solid $gray-600;
        border-radius: 6px;
        color: $gray-600;
        cursor: pointer;
        font-size: 13px; //TODO: replace with icon size variables
        height: 24px;
        line-height: 24px;
        position: absolute;
        right: 8px;
        text-align: center;
        top: 8px;
        width: 24px;
        
        &--no-border{
            border: 0;
            height: auto;
            width: auto;
            color: $gray-500;
            right: 10px;
            top: 10px;
        }
        
        @include icon-reset;
    }

    &__content {
        z-index: $z-balloon;
    }
}