//
// Table
// TODO: check on '.table--new' selector on the bottom of the sheet
//


.table {
	word-break: break-word;

	&--scroll {
		white-space: nowrap;
	}

	thead {
		th, td {
			font-weight: $table-head-font-weight;
			font-size: $table-head-font-size;
			border-bottom-width: 1px;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}

	th, td {
		vertical-align: middle;
		height: 60px;
	}

	&.va-middle {
		tbody {
			th, td {
				vertical-align: middle;
			}

			tr:hover {
				cursor: grab;
				color: $orange;
			}
		}
	}

	&.compacter-table {
		th, td {
			padding: 0.60rem 0.75rem;
		}
	}


	&.table-head-borderless {
		thead {
			th, td {
				border-top: 0;
			}
		}
	}

	&.table-head-solid {
		thead {
			th, td {
				background-color: $table-head-bg;
			}
		}
	}

	&.table-light {
		@each $name, $color in $theme-colors {
			&-#{$name} {
				thead {
					tr {
						color: $color;
						background-color: rgba($color, 0.1);
					}
				}
			}
		}
	}

	&.table-head-custom {
		thead {
			tr, th {
				font-weight: $table-compact-head-font-weight;
				color: $table-compact-head-color !important;
				font-size: $table-compact-head-font-size;
				text-transform: $table-compact-head-text-transform;
				letter-spacing: $table-compact-head-letter-spacing;
			}
		}
	}

	&.table-foot-custom {
		tfoot {
			th, td {
				font-weight: $table-compact-head-font-weight;
				color: $table-compact-head-color !important;
				font-size: $table-compact-head-font-size;
				text-transform: $table-compact-head-text-transform;
				letter-spacing: $table-compact-head-letter-spacing;
			}
		}
	}

	&.table-head-bg {
		thead {
			tr, th {
				background-color: $table-head-bg;
				border-bottom: 0;
				letter-spacing: 1px;

				&:first-child {
					border-top-left-radius: $border-radius;
					border-bottom-left-radius: $border-radius;
				}

				&:last-child {
					border-top-right-radius: $border-radius;
					border-bottom-right-radius: $border-radius;
				}
			}
		}
	}

	&.table-foot-bg {
		tfoot {
			th, td {
				border-bottom: 0;
				background-color: $gray-100;

				&:first-child {
					border-top-left-radius: $border-radius;
					border-bottom-left-radius: $border-radius;
				}

				&:last-child {
					border-top-right-radius: $border-radius;
					border-bottom-right-radius: $border-radius;
				}
			}
		}
	}

	&.table-separate {
		th, td {
			border-top: 0;
			border-bottom: 1px solid $table-border-color;

			&:first-child {
				padding-left: 0 !important;
			}

			&:last-child {
				padding-right: 0 !important;
			}
		}

		tfoot {
			th, td {
				border-bottom: 0;
				border-top: 1px solid $table-border-color;
			}
		}

		tbody {
			tr:last-child {
				td {
					border-bottom: 0;
				}
			}
		}
	}

	&.table-bordered {
		tfoot {
			th, td {
				border-bottom: 0;
			}
		}
	}

	&.table-campaign {
		border-bottom: 1px solid #EBEDF3;
		margin-bottom: 1.875rem;

		td {
			padding: 0.90rem 0.75rem;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		th {
			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}
}

.table:not(.table-bordered) {
	thead {
		th, td {
			border-top: 0;
		}
	}
}



// .table {
// 	&.table-scroll {
// 		tbody {
// 			display: block;
// 			width: 100%;
// 			max-height: 180px;
// 			overflow: scroll;
// 			margin-top: -1px;
// 			tr {
// 				display: table-row;
// 			}
// 		}
// 	}
// }

.table {
	&.table-ordenable {
		th {
			cursor: pointer;

			span {
				pointer-events: none;

				&.asc {
					& + span {
						rect:last-of-type,
						path:last-of-type {
							fill: $primary;
						}
					}

				}
				&.desc {
					& + span {
						rect:first-of-type,
						path:first-of-type {
							fill: $primary;
						}
					}
				}
			}
		}
	}
	&.table-scroll {
		display: flex;
		flex-direction: column;
		max-height: 100%;

		tbody {
			flex-grow: 1;
			overflow-y: auto;
		}

		thead, tbody tr {
			display: table;
			width: 100%;
			table-layout: fixed;
		}
	}
}

// Entire row link
// an anchor tag with the class "table__row-link"
// will occupies the entire row of a table
// with the class "table--row-link"
// TODO: in tables revision the href should be 
// passed by parameter and the anchor should be 
// added inside the component as well as the selectors

/*! purgecss start ignore */

body:not([browser="safari"]) {
	.table--row-link {
		.table__row-link-wrapper {
			height: 0;
			padding: 0;
			width: 0;
		}
	
		tr {
			position: relative;
		}
	
		.table__row-link {
			background: transparent;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
}

/*! purgecss end ignore */

//
// New table style 
// TODO: replace this style for the older one, 
// by using element selector for table instead of
// bootstrap class '.table' and removing  the 
// class 'table' in the tables markups
//

.table--new {
	tr:nth-child(even) {
		background: $gray-100;
	}
	
	tr:last-child {
		border-bottom: $border-width solid $gray-100;
	}
	
	th {
		background: $gray-100;
		font-weight: $font-weight-bolder;
		font-size: $font-size-lg;
		padding: 1rem 0.75rem;
	}
	
	th:first-child {
		border-radius: $border-radius 0 0 0;
		border-left-width: $border-width;
	}
	
	th:last-child {
		border-radius: 0 $border-radius 0 0;
	}
	
	td {
		font-size: $font-size-sm;
		border-right: $border-width solid $gray-100;
		padding: 16px;
	}
	
	td:first-child {
		border-left: $border-width solid $gray-100;
	}

	&.table-ordenable {
		th {
			cursor: pointer;

			span {
				pointer-events: none;

				&.asc {
					& + span {
						rect:last-of-type,
						path:last-of-type {
							fill: $primary;
						}
					}
	
				}
				
				&.desc {
					& + span {
						rect:first-of-type,
						path:first-of-type {
							fill: $primary;
						}
					}
				}
			}
		}
	}
}
