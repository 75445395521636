//
// Buttons
//


// Base
.btn {
	font-size: 14px;
	font-weight: 600;
	min-height: 40px;
	outline: none !important;
	vertical-align: middle;

	// Transition
	@include button-custom-transition($transition-link);

	&:focus:not(.btn-shadow):not(.btn-shadow-hover) {
		box-shadow: none !important;
	}

	// Button Icon variant
	&-icon {
		min-height: unset;
	}

	// Button styles
	&-square {
		border-radius: 0;
	}

	&-pill {
		border-radius: 2rem;
	}

	&-text {
		cursor: text;
	}

	&:not(.btn-text) {
		cursor: pointer;
	}

	&-borderless {
		border-color: transparent;
	}

	&.full-width {
		width: 100%;
	}

	//Button Colors
	// Theme colors
	@each $name, $value in $theme-colors {

		// Default Style
		&-#{$name} {
			$btn-background: $value;
			$btn-border: $value;
			$btn-color: theme-inverse-color($name);
			$btn-icon: theme-inverse-color($name);
			$btn-box-shadow: null;

			$btn-hover-background: transparent;
			$btn-hover-border: $value;
			$btn-hover-color: $value;
			$btn-hover-icon: $value;
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Outline Style
		&-outline-#{$name} {
			$btn-background: transparent;
			$btn-border: $value;
			$btn-color: theme-outline-inverse-color($name);
			$btn-icon: theme-outline-inverse-color($name);
			$btn-box-shadow: null;

			$btn-hover-background: transparent;
			$btn-hover-border: $value;
			$btn-hover-color: $value;
			$btn-hover-icon: theme-inverse-color($name);
			$btn-hover-box-shadow: null;

			$btn-active-background: transparent; //$btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $value;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			&.primary-icon {
				&:hover {
					border-color: $primary !important;
				}
			}

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Outline Hover Style
		&-hover-outline-#{$name} {
			$btn-background: null;
			$btn-border: null;
			$btn-color: null;
			$btn-icon: null;
			$btn-box-shadow: null;

			$btn-hover-background: transparent !important;
			$btn-hover-border: $value !important;
			$btn-hover-color: $value !important;
			$btn-hover-icon: $value !important;
			$btn-hover-box-shadow: null;

			$btn-active-background: transparent !important; //$btn-hover-background;
			$btn-active-border: $btn-hover-border !important;
			$btn-active-color: $value !important;
			$btn-active-icon: $btn-hover-icon !important;
			$btn-active-box-shadow: $btn-hover-box-shadow !important;

			&.primary-icon {
				&:hover {
					border-color: $primary !important;
				}
			}

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Light Style
		&-light-#{$name} {
			$btn-background: theme-light-color($name);
			$btn-border: transparent;
			$btn-color: $value;
			$btn-icon: if($name == secondary, #fff, $value);
			$btn-box-shadow: null;

			$btn-hover-background: transparent !important;
			$btn-hover-border: rgba($value, 0.1);
			$btn-hover-color: $value;
			$btn-hover-icon: $value;
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Transparent Style
		&-transparent-#{$name} {
			$btn-background: rgba($value, 0.1);
			$btn-border: transparent;
			$btn-color: $value;
			$btn-icon: $value;
			$btn-box-shadow: null;

			$btn-hover-background: $value;
			$btn-hover-border: transparent;
			$btn-hover-color: theme-inverse-color($name);
			$btn-hover-icon: theme-inverse-color($name);
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Transparent Hover Style
		&-hover-transparent-#{$name} {
			$btn-background: transparent;
			$btn-border: transparent;
			$btn-color: $value;
			$btn-icon: $value;
			$btn-box-shadow: null;

			$btn-hover-background: rgba($value, 0.1);
			$btn-hover-border: transparent;
			$btn-hover-color: $value;
			$btn-hover-icon: $value;
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Light Hover Style
		&-hover-light-#{$name} {
			$btn-background: null;
			$btn-border: null;
			$btn-color: null;
			$btn-icon: null;
			$btn-box-shadow: null;

			$btn-hover-background: theme-light-color($name);
			$btn-hover-border: transparent;
			$btn-hover-color: $value;
			$btn-hover-icon: $value;
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);

			// Integrate btn-clean
			&.btn-clean {
				border: 0 !important;
			}
		}

		// Link Style
		&-link-#{$name} {
			border: 0;
			padding: 0 !important;
			border-radius: 0 !important;

			$btn-background: transparent !important;
			$btn-border: transparent !important;
			$btn-color: $value;
			$btn-icon: $value;
			$btn-box-shadow: null;

			$btn-hover-background: $btn-background;
			$btn-hover-border: $btn-border;
			$btn-hover-color: theme-hover-color($name);
			$btn-hover-icon: theme-hover-color($name);
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Hover Style
		&-hover-#{$name} {
			$btn-background: null;
			$btn-border: null;
			$btn-color: null;
			$btn-icon: null;
			$btn-box-shadow: null;

			$btn-hover-background: $value !important;
			$btn-hover-border: $value !important;
			$btn-hover-color: theme-inverse-color($name) !important;
			$btn-hover-icon: theme-inverse-color($name) !important;
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);

			// Integrate btn-clean
			&.btn-clean {
				border: 0 !important;
			}
		}

		// Hover Background Style
		&-hover-bg-#{$name} {
			$btn-background: null;
			$btn-border: null;
			$btn-color: null;
			$btn-icon: null;
			$btn-box-shadow: null;

			$btn-hover-background: $value !important;
			$btn-hover-border: $value !important;
			$btn-hover-color: null;
			$btn-hover-icon: null;
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Background Style
		&-bg-#{$name} {
			$btn-background: $value;
			$btn-border: $value;
			$btn-color: null;
			$btn-icon: null;
			$btn-box-shadow: null;

			$btn-hover-background: null;
			$btn-hover-border: null;
			$btn-hover-color: null;
			$btn-hover-icon: null;
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Shadow Style
		&-#{$name}.btn-shadow {
			$btn-background: null;
			$btn-border: null;
			$btn-color: null;
			$btn-icon: null;
			$btn-box-shadow: 0px 9px 16px 0px rgba(theme-shadow-color($name), 0.25) !important;

			$btn-hover-background: null;
			$btn-hover-border: null;
			$btn-hover-color: null;
			$btn-hover-icon: null;
			$btn-hover-box-shadow: null;
			$btn-hover-box-shadow: 0px 9px 16px 0px rgba(theme-shadow-color($name), 0.3) !important;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Hover Shadow Style
		&-#{$name}.btn-shadow-hover {
			$btn-background: null;
			$btn-border: null;
			$btn-color: null;
			$btn-icon: null;
			$btn-box-shadow: null;

			$btn-hover-background: null;
			$btn-hover-border: null;
			$btn-hover-color: null;
			$btn-hover-icon: null;
			$btn-hover-box-shadow: 0px 9px 16px 0px rgba(theme-shadow-color($name), 0.3) !important;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}
	}

	// Theme text colors
	@each $name, $value in $theme-text-colors {

		// Text Style
		&-text-#{$name} {
			$btn-background: null;
			$btn-border: null;
			$btn-color: $value;
			$btn-icon: null;
			$btn-box-shadow: null;

			$btn-hover-background: null;
			$btn-hover-border: null;
			$btn-hover-color: null;
			$btn-hover-icon: null;
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Icon Style
		&-icon-#{$name} {
			$btn-background: null;
			$btn-border: null;
			$btn-color: null;
			$btn-icon: $value;
			$btn-box-shadow: null;

			$btn-hover-background: null;
			$btn-hover-border: null;
			$btn-hover-color: null;
			$btn-hover-icon: null;
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Hover Text Style
		&-hover-text-#{$name} {
			$btn-background: null;
			$btn-border: null;
			$btn-color: null;
			$btn-icon: null;
			$btn-box-shadow: null;

			$btn-hover-background: null;
			$btn-hover-border: null;
			$btn-hover-color: $value !important;
			$btn-hover-icon: null;
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		// Hover Icon Style
		&-hover-icon-#{$name} {
			$btn-background: null;
			$btn-border: null;
			$btn-color: null;
			$btn-icon: null;
			$btn-box-shadow: null;

			$btn-hover-background: null;
			$btn-hover-border: null;
			$btn-hover-color: null;
			$btn-hover-icon: $value !important;
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}
	}

	// Social buttons
	@each $name, $value in $social-colors {
		&-#{$name} {
			$btn-background: get($value, base);
			$btn-border: get($value, base);
			$btn-color: get($value, inverse);
			$btn-icon: get($value, inverse);
			$btn-box-shadow: null;

			$btn-hover-background: get($value, hover);
			$btn-hover-border: get($value, hover);
			$btn-hover-color: get($value, inverse);
			$btn-hover-icon: get($value, inverse);
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}

		&-light-#{$name} {
			$btn-background: get($value, light);
			$btn-border: transparent;
			$btn-color: get($value, base);
			$btn-icon: get($value, base);
			$btn-box-shadow: null;

			$btn-hover-background: get($value, base);
			$btn-hover-border: transparent;
			$btn-hover-color: get($value, inverse);
			$btn-hover-icon: get($value, inverse);
			$btn-hover-box-shadow: null;

			$btn-active-background: $btn-hover-background;
			$btn-active-border: $btn-hover-border;
			$btn-active-color: $btn-hover-color;
			$btn-active-icon: $btn-hover-icon;
			$btn-active-box-shadow: $btn-hover-box-shadow;

			@include button-custom-variant(
				$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
				$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
				$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
			);
		}
	}

	&-light-secondary{
		background-color: #D7DAE7 !important;
		color: $secondary !important;

		&:hover {
			border-color: $secondary;
		}

		.svg-icon svg g [fill]{
			fill: #3F4254 !important;
		}

		&.btn-update {
			.svg-icon {
				position: relative;
				top: -1px;
			}
		}
	}

	&-warning, 
	&-light-warning {
		color: $black !important;

		.svg-icon svg g [fill]{
			fill: $black !important;
		}
	}

	&-orange-secondary{
		background-color: #FDDDD3 !important;
		color: #F35725 !important;

		.svg-icon svg g [fill]{
			fill: #F35725 !important;
		}
	}

	// Others
	.svg-icon-third{
		svg g circle{
			fill: #1F1451 !important;
			opacity: 1;
		}

		svg g path{
			fill: white !important;
		}

	}
}

.btn-outline {
	border: 2px solid $gray-300;
	&.form-select-c {
		padding-top: 0.825rem;
		padding-bottom: 0.825rem;
		padding-left: 1.35rem;
		text-align: left;
		width: 100%;
		font-size: 1.08rem;
		line-height: 1.5;
	}
}

.btn-tertiary-outline {
	border: 1px solid $tertiary;
	color: $tertiary;
	padding: 10px 14px;

	&:hover {
		background: $tertiary-hover;
		color: $tertiary-inverse;
	}
}

.btn-fixed-bottom {
	position:fixed;
	bottom:0;
	left:0;
	right: 0;
	margin-left:auto;
	margin-right:auto;
	text-align:center;
	z-index:999;
}

.btn-bluedark-outline {
	border: 1px solid $blue-dark;
	padding: 10px 14px;
	color: $blue-dark;

	&:hover {
		background: $blue-dark;
		color: $white;
	}
}

// Icons
.btn {
	// Icons
	i {
		font-size: 1.3rem;
		padding-right: 0.35rem;
		vertical-align: middle;
		line-height: 1;
		display: inline-flex;

		&:after,
		&:before {
			//line-height: 0;
		}
	}

	// Svg icons
	.svg-icon {
		margin-right: 0.5rem;
		@include svg-icon-size(18px);
	}

	// Sizes
	&.btn-xs {
		min-height: 24px;

		i {
			font-size: 1rem;
			padding-right: 0.3rem;
		}

		.svg-icon {
			margin-right: 0.3rem;
			@include svg-icon-size(16px);
		}
	}

	&.btn-sm {
		min-height: 32px;

		i {
			font-size: 1.3rem;
			padding-right: 0.35rem;
		}

		.svg-icon {
			margin-right: 0.35rem;
			@include svg-icon-size(16px);
		}
	}

	&.btn-lg {
		min-height: 48px;
		
		i {
			font-size: 1.5rem;
			padding-right: 0.75rem;
		}

		.svg-icon {
			margin-right: 0.75rem;
			@include svg-icon-size(20px);
		}
	}

	// Fixed icon button
	&.btn-icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		height: $input-height;
		width: $input-height;

		svg {
			g [fill] {
				fill: rgba($secondary, 0.5);
			}
		}
		&:not(.disabled):hover {
			svg {
				g [fill] {
					fill: $secondary;
				}
			}
		}
		// Sizes
		&.btn-xxs {
			height: $btn-icon-size-xxs;
			width: $btn-icon-size-xxs;
		}

		&.btn-xs {
			height: $btn-icon-size-xs;
			width: $btn-icon-size-xs;
		}

		&.btn-sm {
			height: $input-height-sm;
			width: $input-height-sm;
		}

		&.btn-lg {
			height: $input-height-lg;
			width: $input-height-lg;
		}

		&.btn-circle {
			border-radius: 50%;
		}

		i {
			padding: 0;
			margin: 0;
            color: inherit;
		}

		[class^="socicon-"],
		[class*=" socicon-"] {
			&:before {
				line-height: 1;
			}
		}

		.svg-icon {
			margin: 0;
			padding: 0;
		}
		&.btn-icon-secondary{
			// width: auto;
			// padding: 0 5px 0px 10px;
			i{
				font-size: 12px;
			}
		}

		@each $name, $value in $theme-colors{
			&.btn-#{$name}{
				background: $value;
				color: theme-inverse-color($name);
				border: 1px solid $value;

				&:hover,
				&:focus {
					background: $gray-100;
					color: $value;
				}
			}

			&.btn-#{$name}-outline{
				background: none;
				color: $gray-500;
				border: 1px solid $gray-100;

				&:hover,
				&:focus {
					background: $gray-100;
					color: $value;
				}
			}
		}

		&.btn-clean{
			i {
				color: inherit;
			}

			&:hover {
				background: $gray-100;
				border: 1px solid $gray-100;
				color: $primary;
			}
		}
	}

	// Fixed Height
	&.btn-fixed-height {
		padding-top: 0;
		padding-bottom: 0;
		display: flex;
		align-items: center;

		&.btn {
			height: $input-height;
		}

		&.btn-sm {
			height: $input-height-sm;
		}

		&.btn-lg {
			height: $input-height-lg;
		}
	}
}



// Dropdown Toggle
.dropdown-toggle {
	&.nav-link,
	&.form-control,
	&.btn {
		// Arrow icon
		&:before,
		&:after {
			transition: $transition-link;
			text-align: center;
			display: inline;
			border: 0;
			font-size: 0.6rem;
			line-height: 0;
			vertical-align: middle;
			position: relative;
		}

		&:after {
			margin-left: 0.5rem#{'/*rtl:ignore*/'};
			@include ki(get($ki-types, arrowDown));
		}

		&:before {
			margin-right: 0.5rem#{'/*rtl:ignore*/'};
			@include ki(get($ki-types, arrowBack));
			display: none;
		}

		&.dropdown-toggle-split {
			opacity: 0.9;

			&:after,
			&:before {
				margin: 0;
			}
		}

		&.form-control {
            &.disabled,
            &:disabled {
                background: $input-disabled-bg;
                border-color: $input-disabled-bg;
            }
        }

		&.dropdown-toggle-border {
			position: relative;
			padding-right: 50px;

			&:after {
				border-left: 0.02em solid currentColor;
				padding: 10px 0px 5px 10px;
				margin-left: 15px;
				position: absolute;
				top: 5px;
				right: 11px;
				height: calc(100% - 10px);
			}
		}

		.show > &,
		&.active,
		&:focus,
		&:active,
		&:hover {
			&:after,
			&:before {
				transition: $transition-link;
			}

			&.dropdown-toggle-split {
				opacity: 1 !important;
			}
		}
	}

	&.btn-sm {
		&:after {
			top: 12px !important;
		}
	}

	&.full-width {
		width: 100%;
		position: relative;
		&:after {
			color: inherit !important;
			right: 10px;
			position: absolute;
			top: calc(50% - 1.5em);
			z-index: 1;
			display: inline;
			vertical-align: middle;
			line-height: inherit;
			margin-top: 0.55rem;
			&:hover {
				color: inherit;
			}
		}
	}

	.dropdown.w-inputtext & {
		padding: 8px;
		position: absolute;
		border: 0;
		width: 36px;
		background: transparent;
		top: 0;
		right: 0;
	}

}

.btn-group {
	// Direction
	&.dropup > .btn.dropdown-toggle {
		&:after {
			@include ki(get($ki-types, arrowUp));
		}
	}

	&.dropright > .btn.dropdown-toggle {
		&:after {
			@include ki(get($ki-types, arrowNext));
		}
	}

	&.dropleft > .btn {
		@include border-top-right-radius($btn-border-radius !important);
		@include border-bottom-right-radius($btn-border-radius !important);

		&.dropdown-toggle-split {
			@include border-top-right-radius(0 !important);
			@include border-bottom-right-radius(0 !important);
		}

		&.dropdown-toggle {
			&:after {
				display: none;
			}

			&:before {
				display: inline;
			}
		}
	}
}

.btn-group {
	&.btn-group-switch {
		.btn {
			font-weight: 400;
			&.btn-outline-gray {
				&.active,
				&:hover {
					background-color: $gray-100 !important;
					color: $gray-800 !important;
				}
			}
		}
	}
}

// Clean button
.btn.btn-clean {
	$btn-background: transparent;
	$btn-border: transparent;
	$btn-color: $gray-500;
	$btn-icon: $gray-500;
	$btn-box-shadow: null;

	$btn-hover-background: $gray-100;
	$btn-hover-border: transparent;
	$btn-hover-color: $primary;
	$btn-hover-icon: $primary;
	$btn-hover-box-shadow: null;

	$btn-active-background: $btn-hover-background;
	$btn-active-border: $btn-hover-border;
	$btn-active-color: $btn-hover-color;
	$btn-active-icon: $btn-hover-icon;
	$btn-active-box-shadow: $btn-hover-box-shadow;

	@include button-custom-variant(
		$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
		$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
		$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
	);
}

// Default button
.btn.btn-default {
	$btn-background: $gray-100;
	$btn-border: $gray-100;
	$btn-color: $gray-500;
	$btn-icon: $gray-500;
	$btn-box-shadow: null;

	$btn-hover-background: $gray-100;
	$btn-hover-border: $gray-100;
	$btn-hover-color: $primary;
	$btn-hover-icon: $primary;
	$btn-hover-box-shadow: null;

	$btn-active-background: $btn-hover-background;
	$btn-active-border: $btn-hover-border;
	$btn-active-color: $btn-hover-color;
	$btn-active-icon: $btn-hover-icon;
	$btn-active-box-shadow: $btn-hover-box-shadow;

	@include button-custom-variant(
		$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
		$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
		$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
	);
}

.btn-dashed {
	border-style: dashed;
}
	
.choice {
	position: relative;
	display: block;
	text-transform: none;
	padding: 0px 0;
	margin-bottom: 15px;
	
	//hide element radio
	input[type="radio"]:empty {
		display: none;
	}
	  
	  //css label
	  label {
		width: 100%;
		height: 100%;
		border: 0;      
		cursor: pointer;
		box-sizing: border-box;
		margin: 0 0;
		background: transparent;
		padding: 5px 15px;
		color: black;
		text-align: left;
		position: relative;
		opacity: 0.5;

		.drinkcard-type{
			padding-left: auto;
			filter: none;
		}

	  }  
	  
	  //label check
	  input[type="radio"]:checked ~ label {
		opacity: 1;
		background-color: #EFF2F7;
		border-radius: 6px;
  
		&:after {display: none;}
	  }
	
}
  

// Btn Plus

.btn-plus {
	border: none;
	box-shadow: none;
	padding-right: 2.3em;
	&.dropdown-toggle {
		&:after {
			display: none;
		}
	}
	& span.svg-icon {
		position: absolute;
		top: 0.17em;
		margin-right: 0;
		right: 5px;
		color: inherit;
		svg {
			g {
				[fill] {
					fill: currentColor;
					color: inherit;
				}
			}
		}
	}
	& + .dropdown-menu {
		padding: 8px 8px 4px;
		top: 4px !important;
		width: 240px;
		.badge {
			margin: 0 4px 4px 0;
		}
	}
}

// grey border, white background, grey text button (not in the design system)

.btn-secondary-outline {
	border: $border-width solid $gray-500; 
	border-radius: $border-radius;
	color: $gray-500;
	background: transparent;
	padding: 4px;
	white-space: nowrap;
	
	&:hover {
		border-color: $gray-600;
		color: $gray-600;
	}
}
