//
// Dropdown
// TODO: (1) - review absolute/relative length units after type system refactoring is merged
//

.dropdown {
    $self: &;
    border-radius: $border-radius;
    
    &--new {
        #{ $self }-toggle {
            align-items: center;
            background: transparent;
            border: 1px solid transparent;
            border-radius: $border-radius;
            display: inline-flex;
            color: $gray-800;
            font-weight: $font-weight-bolder;
            height: 40px;
            padding: 5.5px 35px 5.5px 12px; //TODO: (1) 
            position: relative;
            width: 100%;
    
            &::after {
                border: none;
                border-color: currentColor;
                border-top: 0.15em solid;
                border-left: 0.15em solid;
                height: .5em;
                position: absolute;
                right: 11px;
                top: 50%;
                transform: translate(-50%, -75%) rotate(225deg);
                width: .5em;    
            }
    
            &:is(&--paginate *) {
                background-color: $gray-100;
                border-radius: $border-radius;
                padding: 0.5em 1em; //TODO: (1) 
            }

            &--icon{
                display: flex;
                column-gap: 8px;
                justify-content: center;
                align-items: center;
            }

            @each $name, $value in $theme-colors {
		
              // Default Style
              &--#{$name} {
                background-color: $value;
                border: $border-width solid transparent;
                color: theme-inverse-color($name);
          
                &:hover,
                &:active,
                &:focus {
                  background-color: theme-hover-color($name);
                }
              }
          
              // Outline Style
              &--#{$name}-outline {
                background-color: theme-inverse-color($name);
                border: $border-width solid currentColor;
                color: $value;
          
                &:hover,
                &:active,
                &:focus {
                  background-color: theme-light-color($name);
                }
              }
            }
        }

        #{ $self }-toggle:where(.dropdown.show .dropdown-toggle) {
            color: $primary;
    
            &:after {
                transform: translate(-50%, -25%) rotate(45deg);
            }        
        }

        #{ $self }-menu {
            box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.05);
            margin: 0;
            padding: 0;
        }

        #{ $self }-item {
            border-radius: 0;
            color: inherit;
            font-weight: $font-weight-bold;
            margin: 0;
            padding: 4px; //TODO: (1)
            width: 100%;
    
            &:hover {
                background: transparent;
            }
    
            &--active {
                color: $primary;
    
                .dropdown-item__inner {
                    background-color: $gray-100;
                }
            }

            &:not(:last-child) {
                border-bottom: $border-width solid $gray-200;
            }
    
            &__inner {
                border-radius: $border-radius;
                padding: 8px 12px; //TODO: (1) 
                width: 100%;
            }
            
            &:hover .dropdown-item__inner {
                background-color: $gray-100;
            }

            &--link {
                cursor: pointer;

                &:hover {
                    color: $primary;
                }

                i {
                    color: inherit;
                }
            }
        }
    }

    &--borderless {
        #{ $self }-toggle {
            padding: 8px 2em 8px 2px; //TODO: (1)
            border: none;
            border-radius: 0;
            height: auto;

            &::after {
                right: 0;   
            }
        }
    }

    &--button{
        #{ $self }-toggle {
            padding: 0;
        
            &:after{
                content: none;
            }
        }
    }

    @include icon-reset;
}

@include isChildOf('.dropdown-toggle', '.dropdown--new.show') {
    color: $primary;
}
