//
// Scrolltop
//

.scrolltop {
    align-items: center;
    background-color: transparent;
    border: $border-width solid $gray-600;
    border-radius: 50%;
    bottom: 25px;
    color: $gray-600;
    cursor: pointer;
    display: none;
    font-size: 16px;
    justify-content: center;
    height: 24px;
    opacity: 0;
    position: fixed;
    right: 64px;
    transition: $transition-link;
    width: 24px;
    z-index: 100;

    & .svg-icon svg { // TODO: delete after icon refactoring;
        height: 1em;
        width: 1em;

        & path { // TODO: delete after icon refactoring;
            fill: currentColor !important;
        }
    }

    [data-scrolltop="on"] & {
        opacity: 1;
        animation: animation-scrolltop .4s ease-out 1;
        display: flex;
    }

    // // Tablet & Mobile Modess
    // @include media-breakpoint-down(md) {
    //     bottom: get($scrolltop-config, bottom, tablet-and-mobile);
    //     right: get($scrolltop-config, right, tablet-and-mobile);
    //     width: get($scrolltop-config, size, tablet-and-mobile);
    //     height: get($scrolltop-config, size, tablet-and-mobile);
    // }
}

// Animations
@keyframes animation-scrolltop {
    from   { margin-bottom: -15px }
    to {     margin-bottom: 0; }
}
