//
// Card
//

// Base

.card {
    border: 0;
    .card-header {
        display: flex;
        // Title
        .card-title {
            margin-bottom: 0;
            width: 100%;
            .h5 {
                font-weight: $font-weight-bolder;
                margin-bottom: 0.3rem;
            }
        }
    }
    &--border{
        border: 1px solid #EAEDF2;
        border-radius: 4px;
    }
}
.card.card-custom {
    box-shadow: $card-box-shadow;
    border: 0;

    // Header
    > .card-header {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        //height: $card-header-height;
        min-height: $card-header-height;
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;

        // Title
        .card-title {
            display: flex;
            align-items: center;
            margin: $card-header-spacer-y;
            margin-left: 0;
            //flex-wrap: wrap;

            .card-icon {
                margin-right: 0.75rem;
                line-height: 0;

                i {
                    font-size: 1.25rem;
                    color: $dark-50;
                    line-height: 0;

                    &:after,
                    &:before {
                        line-height: 0;
                    }
                }

                .svg-icon {
                    @include svg-icon-size(24px);
                    @include svg-icon-color($dark-50);
                }
            }

            &,
            .card-label {
                font-weight: 500;
                font-size: 1.275rem;
                color: $dark;
            }

            .card-label {
                margin: 0 0.75rem 0 0;
                flex-wrap: wrap;
            }

            // Description
            small {
                color: $text-muted;
                font-size: 1rem;
            }
        }

        // Toolbar
        .card-toolbar {
            display: flex;
            align-items: center;
            margin: $card-header-spacer-y 0;
            flex-wrap: wrap;
        }

        // Line tabs integration
        &.card-header-tabs-line {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            align-items: stretch;

            .card-toolbar {
                margin: 0;
            }

            .nav {
                border-bottom-color: transparent;

                .nav-item {
                    align-items: stretch;
                }

                .nav-link {
                    padding-top: $card-spacer-y;
                    padding-bottom: $card-spacer-y;
                }
            }
        }

        &.card-header-right {
            justify-content: flex-end;
        }

        
    }

    // Body
    > .card-body {
         padding: $card-spacer-y $card-spacer-x;
         .card-body  {
            padding: $card-spacer-y 0;
         }
         .card-header {
            padding-left: 0;
            padding-right: 0;
         }
         .card-footer {
            padding: $card-spacer-y 0;
         }
    }

    // Footer
    > .card-footer {
        background-color: transparent;
    }

    // Make full height
    &.card-stretch {
        display: flex;
        align-items: stretch !important;
        flex-direction: column;
        height: 100%;

        &.gutter-b {
            height: calc(100% - #{$grid-gutter-width});
        }

        &.card-stretch-half {
            height: 50%;

            &.gutter-b {
                height: calc(50% - #{$grid-gutter-width});
            }
        }

        &.card-stretch-third {
            height: 33.33%;

            &.gutter-b {
                height: calc(33.33% - #{$grid-gutter-width});
            }
        }

        &.card-stretch-fourth {
            height: 25%;

            &.gutter-b {
                height: calc(25% - #{$grid-gutter-width});
            }
        }
    }

    // Card Profile
    &.card-custom-secondary{
        background-color: transparent;
        box-shadow: none;
        > .card-header {
            padding-left: 0;
            padding-right: 0;
        }
        > .card-body {
            padding: $card-spacer-y 0;
            .card-body  {
               padding: $card-spacer-y $card-spacer-x;
            }
            .card-header {
               padding-left: $card-spacer-x;
               padding-right: $card-spacer-x;
            }
            .card-footer {
               padding: $card-spacer-y $card-spacer-x;
            }
        }
        > .card-footer {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .c-border{
        border: 1px solid #f3f6fa;
    }

    // Card header fit style
    &.card-fit {
        > .card-header {
            border-bottom: 0;
        }

        > .card-footer {
            border-top: 0;
        }
    }

    // Card space style
    &.card-space {
        padding-left: $card-spacer-x;
        padding-right: $card-spacer-x;

        > .card-header {
            padding-left: 0;
            padding-right: 0;
        }

        > form > .card-body,
        > .card-body {
            padding-left: 0;
            padding-right: 0;
        }

        > form > .card-footer,
        > .card-footer {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // Sticky card
    &.card-sticky {
        > .card-header {
            transition: left 0.3s, right 0.3s, height 0.3s;
    		//height: $card-sticky-header-height;
    		min-height: $card-sticky-header-height;
    	}
    }

    .card-sticky-on &.card-sticky {
        > .card-header {
            transition: left 0.3s, right 0.3s, height 0.3s;
			position: fixed;
			box-shadow: $card-sticky-shadow;
			z-index: $card-sticky-zindex;
			background: $card-sticky-header-bg;
    	}
    }

    // Transparent Background
    &.card-transparent {
        background-color: transparent;
    }

    // No Shadow
    &.card-shadowless {
        box-shadow: none;
    }

    // Border style
    &.card-border {
        box-shadow: none;
        border: 1px solid $card-border-color;
    }

    // Scroll
    .card-scroll {
        position: relative;
        overflow: auto;
    }

    // Collapsed Mode
	&.card-collapsed {
		> form,
		> .card-body {
			display: none;
		}
	}
    
    &.custom-secondary{
        box-shadow: none;
    }
}

.card{
    &.card-campaign{
        h3{
            font-size: 1rem;
            font-weight: 600;
        }
        .form-group label{
            font-size: 0.9375rem;
        }
        .card-label-campaign{
            h3{
                font-size: 20px;
                font-weight: 500;
            }
            span{
                font-size: 15px;
            }
        }
        .target-campaign{
            .typeahead-control{
                border-color: $gray-300;
            }
        }
        .shedule-campaign{
            input{
                display: block;
                width: 100%;
                height: calc(1.5em + 1.3rem + 2px);
                padding: 0.65rem 1rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #3F4254;
                background-color: #ffffff;
                background-clip: padding-box;
                border: 1px solid $gray-300;
                border-radius: 0.42rem;
                box-shadow: none;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
        }
    }
}

// Utilities
.card-spacer {
    padding: $card-spacer-y $card-spacer-x !important;
}

.card-spacer-x {
    padding-left: $card-spacer-x !important;
    padding-right: $card-spacer-x !important;
}

.card-spacer-y {
    padding-top: $card-spacer-y !important;
    padding-bottom: $card-spacer-y !important;
}

.card-rounded {
    border-radius: $card-border-radius;
}

.card-rounded-top {
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
}

.card-rounded-bottom {
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
}

// Mobile mode
@include media-breakpoint-down(sm) {
    .card.card-custom {
        // Header
        > .card-header:not(.flex-nowrap) {
            min-height: 0;
            padding-top: $card-header-spacer-y;
            padding-bottom: $card-header-spacer-y;
        }
    }
}

// Card related helper classes
.rounded-card {
    @include border-radius($card-border-radius);
}

.rounded-card-top {
    @include border-top-left-radius($card-border-radius);
    @include border-top-right-radius($card-border-radius);
}

.rounded-card-bottom {
    @include border-bottom-left-radius($card-border-radius);
    @include border-bottom-right-radius($card-border-radius);
}

#kt_form{
    .btn-info{
        color: #181C32;
        background-color: #e3e4e5;
        border-color: #e3e4e5;
    }
}


.select-container{
    max-width: 100px;
    .bootstrap-select {
        .dropdown-menu{
            min-width: 540px;
            #bs-select-1-0{
                display: none;
            }
            li{
                display: inline-block;
                &:first-child{
                    display: none
                }
                a{
                    padding: 10px 12px;
                }
            }
            .dropdown-header{
                display: none
            }
            &.inner{
                text-align: left;
                li{
                    .dropdown-header{
                        display: none;
                    }
                }
            }
        }
    }
}


// Card List

.card-body.list-fillet {
    .row {
        position: relative;
        border-bottom: black;
        padding-top: 12px !important;
        padding-bottom: 10px !important;
        &:before {
                position: absolute;
                content: "";
                display: block;
                background-color: #EAEDF2;
                height: 1px;
                width: calc(100% - 23px);
                margin-left: 0;
                left: 50%;
                bottom: -2px;
                @include transform(translateX(-50%));
        }
        &:last-of-type {
            &:before {
                display: none;
            }
        }
    }
}

.card-body.list-first-bg {
    & > .row:nth-of-type(1) {
        
        &:before {
            background: #F3F6F9;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            height: 100%;
            width: 43.66%;
            left: 0px;
            text-align: left;
            left: 0;
            transform: translateX(0%);
            border: 3px solid white;
            border-radius: 6px;  
        }
    }
}


.list-collapse-p {
    font-size: 1rem;
    line-height: 1.5;
}
    

  
  
.list-collapse-p #list-collapse.collapse:not(.show) {
    display: block;
    height: 21rem;
    overflow: hidden;
    & > div {
        opacity: 0.2;
        &:nth-of-type(-n+5) {
            opacity: 1;
        }
    }
}
  
.list-collapse-p #list-collapse.collapsing {
    height: 21rem;
}

.show-more {
    padding: 15px $card-spacer-x;
    font-size: 0.925rem;
    font-weight: 500 !important;
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
}


  /* Help Classes */@at-root

.auto-height {
    height: auto !important;
}



/* Traits Card Profile Page */

.traits-rules {
    .resume {
        h2, p {
            margin-bottom: 15px;
        }
        h2 {
            font-size: 12px;
        }
        p {
            color: $gray-500;
            span {
                color: #383A49;
            }
        }
    }
    .set-rules {
        padding: 10px 10px 10px 62px;
        background-color: $gray-100;
        border-radius: 4px;
        margin-bottom: 30px;
        overflow: hidden;
        display: block;
        .group-rule {
            min-height: 40px;
            float: left;
            width: 100%;
            border: 1px solid $gray-500;
            border-radius: 4px;
            padding: 0 10px;
            position: relative;
            &.matching {
                background-color: white;
                border-color: #7ED286;
                
            }
            & + .group-rule, & + .rule {
                margin-top: 20px;
            }
            & > div[class="rule"]:last-of-type,
            & > div[class="rule matching"]:last-of-type {
                border-bottom: none;
            }
            & > .andor {
                top: -10px;
                left: -17px;
                background: white;
                z-index: 1;
            }
            .rule {
                padding: 10px 0px 10px 40px;
                position: relative;
                border: none;
                border-bottom: 1px solid $gray-500;
                border-radius: 0;
                
                &.matching {
                    background-color: transparent;
                    border-color: $gray-500;
                }

                & + .rule {
                    margin-top: 0px;
                    
                    
                }
                .andor {
                    width: 26px;
                    left: 0px;
                    border-color: $gray-500;
                }
            }
        }
        .andor {
            position: absolute;
            top: 10px;
            height: 20px;
            width: 35px;
            border: 1px solid #7ED286;
            font-size: 9px;
            left: -48px;
            padding: 3px 0;
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;
            border-radius: 4px;
            color: $gray-500;
        }
        .rule {
            min-height: 40px;
            border: 1px solid $gray-500;
            font-size: 12px;
            width: 100%;
            border-radius: 4px;
            padding: 10px;
            position: relative;
            float: left;
            width: 100%;
            & + .rule {
                margin-top: 10px;
            }
            & + .group-rule {
                margin-top: 20px;
            }
           
            &.matching {
                background-color: white;
                border-color: #7ED286;
                .state {
                    display: block;
                }
            }
            .name {width: 28%; float:left;}
            .conditional {width: 25%; float:left;}
            .value {width: 25%; float:left;}
            .state {
                display: none;
                float: left;
                width: 22%;
                div {
                    white-space: nowrap;
                    border-radius: 10px;
                    background-color: #7ED286;
                    font-size: 8px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    padding: 2px 10px 0;
                    color: white;
                    font-weight: 600;
                    float: right;
                    svg {
                        height: 1.6rem;
                        width: 1.2rem;
                        path {fill: white;}
                    }
                }

            }
        }
    }
}



.nav.nav-tabs#edit-tab {
    position: absolute;
    z-index: 10;
    right: 0;
    border-bottom: none;

    li {
        a {
            border: none;
            &:hover {
                svg {
                    path, rect[fill="#000000"] {
                        fill: $orange;
                    }
                }
            }
            &.active {
                border: none;
                display: none !important;
            }
        }
    }
}

.edit-card {



    a.addattr {
        margin: 15px 0 15px;
        font-size: 0.925rem;
        font-weight: 500 !important;
        display: inline-block;
        color: $orange;
        &:hover {
            color: #c1360b;
        }
    }
    .btn-clean {
        padding: 0;
        &:hover {
            background-color: transparent;
        }
        span, svg {
            margin-right: 0;
        }
    }
    .list-group-edit {
        padding: 0;
        margin: 0;
        list-style: none;
        .list-group-item-edit {
            padding: 5px 14px;
            float: left;
            width: 100%;
            display: block;
            position: relative;
            &:before {
                position: absolute;
                content: "";
                display: block;
                background-color: #EAEDF2;
                height: 1px;
                width: calc(100% - 23px);
                margin-left: 0;
                left: 50%;
                bottom: -2px;
                @include transform(translateX(-50%));
            }
            &:last-of-type {
                &:before {
                    //display: none;
                }
            }
        }
        .dragdrop-handle {
            float: left;
            width: 4px;
            left: -8px;
            top: 8px;
            position: relative;
            cursor: grab;
        }
        .select-input-container {
            width: calc(50% - 4px);
            float: left;
            padding: 0 5px;
            select {
                width: 100%;
            }
        }
        .input-container {
            width: calc(50% - 20px);
            float: left;
            padding: 0 5px;
            input {
                width: 100%;;
            }
        }
        .delete-row {
            width: 20px;
            float: left;
            padding-top: 8px;
        }
    }
}

[draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
  }
  


  /* Analytics Builder */@at-root

  /*! purgecss start ignore */
  .analytics-builder-edit {
    .row.a-item-selected {
        .column-background {
            border-color: $orange;
            z-index: 3;
            &:after, &:before {
                border: 1px solid $orange;
            }
        }
    }
    &.a-edit-item-selected {
        position: relative;
        &:after {
            content: "";
            display: block;
            background: rgba($blue-dark,0.2);
            position: fixed;
            top: -20px;
            left: 0px !important;
            width: 100%;
            height: calc(100% + 160px);
            pointer-events: none;
            z-index: 2;
            .profiles-af & {
                position: fixed;
            }
        }
        .column-background.a-item-selected {
            border-color: $orange;
            z-index: 3;
        }
    }
    .column-background {
        position: relative;
        &:hover, &.a-item-selected {
            border: 1px solid $orange;
            &:after, &:before {
                border: 1px solid $orange;
            }
        }
    }

    .card.card-custom.card-stretch.gutter-b {
        height: 100%;
    }
    .card.card-custom {
        & > .card-body,
        & > .card-header {
            & .card-spacer {
                padding: 2rem 1rem !important;
            }
        }
    }
  }
  /*! purgecss end ignore */