/*! purgecss start ignore */
.custom-fields {
  height: 100%;

  &__empty-state {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  &__empty-message {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
  }

  &__items-wrapper {
    margin-top: 12px;
  }

  &__item {
    background: $gray-400;
    border-radius: $border-radius;
    padding: 8px;

    &:hover {
        background-color: $gray-200;
    }

    &:where(:not(:first-child)) {
      margin-top: 4px;
    }

    &:where([draggable="true"]) {
      cursor: grab;
    }
  }
}
/*! purgecss end ignore */
