﻿//
// Content Repository's Main Component
//

.content-repository {
  $self: &;
  display: flex;
  gap: 24px;
  padding: 16px;
  width: 100%;
  // Anchors
  :where(a) {
    cursor: pointer;
  }

  // Buttons
  &__button {
    @extend .button;

    &--delete-outline {
      @extend .button--danger-outline;
    }

    &--black-outline {
      @extend .button--black-outline;
    }

    &--link {
      @extend .button--borderless;
      @extend .button--transparent;
    }

    &--icon {
      @extend .button--icon;

      & *:where(#{$self}__icon) {
        line-height: 1;
      }
    }
  }

  // Labels
  &__label {
    font-size: $font-size-lg;
    margin-bottom: 0;
  }

  // Heading
  &__heading {
    font-size: $font-size-sm;
    font-weight: $font-weight-bolder;
    margin-bottom: 0;
  }

  //Sidebar
  &__sidebar {
    display: flex;
    flex-direction: column;
    width: 250px;
    gap: 0.75rem;

    &-header {
      align-items: center;
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    &-title {
      font-size: 1.175rem;
      color: $black;
      font-weight: 500;
    }

    // Sidebar balloon
    &-balloon {
      position: relative;
    }

    &-action-icon {
      display: flex;
      font-size: map-get($icon-sizes, "xxl");
    }

    &-folders {
      flex-grow: 1;
      padding: 0;
      margin: 0;
      margin-bottom: 2rem;
      list-style: none;

      &-divider {
        margin: 0.5rem;
      }
    }

    // Sidebar storage progress
    &-storage {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      &-title {
        align-items: center;
        display: flex;
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        gap: 0.5rem;
      }

      &-icon {
        color: $gray-600;
        display: flex;
        font-size: map-get($icon-sizes, "xl");
        line-height: 1;
      }

      &-footer {
        color: $gray-600;
      }
    }
  }

  //Floater
  &__floater {
    background: $white;
    border: 1px solid $gray-200;
    border-radius: 4px;
    box-shadow: 0px 1px 4px 0px #0000000d;
    padding: 10px;
    position: absolute;
    z-index: $z-balloon;

    &-header {
      align-items: center;
      display: flex;
      gap: 1rem;
    }

    &-input {
      max-width: 200px;
    }

    &-footer {
      display: flex;
      justify-content: end;
      margin-top: 10px;
    }

    &-info {
      color: $gray-600;
      font-size: 0.925rem;
    }

    &-alert {
      color: $danger;
      font-size: 0.925rem;
    }

    &-button-icon {
      &:hover {
        color: $black;
      }
    }
  }

  //Folders List
  &__folders-item {
    --folder-level: 0;
    --indentation: calc(var(--folder-level) * 1.5rem + 10px);
    border-radius: 0.42rem;
    cursor: pointer;
    align-items: center;
    padding: 10px;
    padding-left: var(--indentation, inherit);
    display: flex;
    flex-direction: row;
    list-style: none;
    transition: all 0.15s ease;
    gap: 5px;
    position: relative;

    &--active {
      background-color: $gray-100;
      color: $gray-800;
      font-weight: 600;
    }

    &:hover {
      color: $primary;
    }

    &-left,
    &-right {
      width: 1.5rem;
    }

    &-icon {
      display: flex;
      font-size: 1.5rem;
      line-height: 1;
      transition: all 120ms ease;

      &--arrow-down {
        transform: rotate(90deg);
      }
    }

    &-body {
      display: flex;
      flex: 1;
      gap: 8px;
      justify-content: space-between;
      overflow: hidden;
    }

    &-name {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
    }

    &-button {
      border-radius: 0.42rem;
      color: $gray-600;
      cursor: pointer;

      &:hover {
        background-color: $gray-200;
      }
    }

    &-options {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 5px;
    }
  }

  //Grid
  &__grid {
    display: flex;
    flex-direction: column;
    flex: 1 1 80%;
  }

  //Grid Header
  &__grid-header {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }

  &__header {
    &-left,
    &-right {
      align-items: center;
      display: flex;
      column-gap: 12px;
    }
  }

  &__search-bar {
    position: relative;
  }

  &__search-menu {
    box-shadow: 0px 1px 4px 0px #0000000d;
    position: absolute;
    z-index: 1;
  }

  //Grid Body
  &__grid-body {
    align-content: flex-start;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 24px;
  }

  &__tile-wrapper {
    align-self: center;
    border: $border-width solid $gray-200;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
    text-align: center;

    &--selected {
      border-color: $primary;
      border-width: 2px;
    }

    &--relative {
      position: relative;
    }

    &:where(#{$self}--bulk #{$self}__tile-wrapper) {
      position: relative;
    }
  }

  &__tile {
    height: 120px;
    object-fit: cover;
    width: 166px;
  }

  &__select-box {
    right: 0.5rem;
    top: 0.5rem;
    position: absolute;
  }

  &__no-results {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    width: 100%;
  }
}

.content-repository__modal {
  position: absolute;
  z-index: 1;
  padding: 0rem 2rem;
  top: 0;
  height: 100%;
  margin: 3rem auto;
}

.content-repository__modal-dialog {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  pointer-events: auto;
}

.content-repository__modal .content-repository__grid-body {
  overflow: scroll;
  max-height: 500px;
}

.content-repository__tile-icon {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
