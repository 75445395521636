// Text Alignment Component

.text-options {
  .form-control.form-control-sm {
    width: auto;
    display: block;
    margin: 0;
    margin-right: 0px;
    letter-spacing: 0px;
    float: left;
    margin-right: -1px;
    position: relative;
    padding-top: 5px;
    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:last-of-type) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      &:checked {
        & + i {
          color: $primary;
        }
      }
    }
  }
}
