//
// Links
//

a {
    color: inherit;
}

a.link,
.link {
    color: $link-color;
    
    &:hover {
        color: $link-hover-color;
    }

    &--alt {
        color: inherit;

        &:hover {
            color: $link-color;
        }
    }
}

a.link-blue,
.link-blue {
    color: $cyan;

    &:hover {
        color: $link-hover-color;
    }

    &--alt {
        color: inherit;

        &:hover {
            color: $link-color;
        }
    }
}