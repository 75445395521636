//
// Switch Checkbox
//

.switch-cb {
    cursor: pointer;
    height: 20px;
    position: relative;
    visibility: hidden;
    width: 92px;

    &:before,
    &:after {
        display: inline-block;
        font-size: $font-size-sm;
        font-weight: $font-weight-bolder;
        height: 100%;
        visibility: visible;
        text-align: center;
        width: 50%;
    }

    &:before {
        background: $primary;
        border: $border-width solid $primary;
        border-radius: $border-radius 0 0 $border-radius;
        color: $white;
        content: "NO";
    }

    &:after {
        background: $white;
        border: $border-width solid $gray-500;
        border-radius: 0 $border-radius $border-radius 0;
        color: $gray-500;
        content: "YES";
    }

    &:checked {
        &:before {
            background: $white;
            border-color: $gray-500;
            color: $gray-500;
        }

        &:after {
            background: $success;
            border-color: $success;
            color: $white;
        }
    }
}
