.overlay-menu {
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  
  &__overlay {
    background-color: rgba(0,0,0, .3);
    position: absolute;
    height: 100%;
    width: 100%;
  }
  
  &:hover {
    opacity: 1;
  }

  &__menu {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    border-radius: $border-radius;
    padding: 8px 0;
    display: inline-flex;
  }
  
  &__menu-item {
    padding: 0 12px;
    color: $info;
  }

  @include icon-reset;
}