//
// Tabs
//

/*! purgecss start ignore */
.tabs {
    align-items: stretch;
    color: $gray-600;
    border-bottom: $border-width solid $gray-200; 
    display: flex;
    font-size: $font-size-lg;
    width: 100%;

    &__item {
        align-items: center;
        cursor: pointer;
        display: flex;
        padding-bottom: 10px;

        &:not(:first-child) {
            margin-left: 16px;
        }

        &--active {
            color: $primary;
            font-weight: $font-weight-bolder;
            position: relative;

            &:after {
                background-color: currentColor;
                bottom: -1px;
                content: "";
                height: $border-width;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }
    }

    @include icon-reset;
}
/*! purgecss end ignore */
