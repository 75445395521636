/*! purgecss start ignore */
.thumbnail {
    width: 100%;
    &--zoom {
        overflow: hidden;
        border: none;
        padding: 2px;
        vertical-align: middle;
        position: relative;
        
        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translateX(-50%) translateY(-50%));
            opacity: 0;
            @include transition(opacity 0.2s ease);
        }
        &:before {
            width: 100%;
            height: 100%;
            background-color: rgba($gray-600, .7);
            z-index: 1;
        }
        &:after {
            width: 30px;
            height: 30px;
            background-image: url("../media/icons/iconEye.png");
            background-size: contain;
            z-index: 1;
        }

        &:hover {
            &:before,
            &:after {
                opacity: 1;
            }
        }
    }
    &__image-container {
        overflow: hidden;
        padding-bottom: 60%;
        position: relative;
        img {
            width: 100%;
            height: auto;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
        }
        svg {
            height: 100%;
            display: block;
            position: absolute;
        }
    }
}


/* Thumbnails containers */

/* Table Cells */
td {
    .thumbnail-container {
        display: flex;
        &__column-image {
            width: 120px;
            padding-right: 15px;
        }
        &__column-text {
            white-space: nowrap;
            display: flex;
        }
        .thumbnail {
            width: 100%;
            &__image-container {
                img {
                    max-width: 100%;
                }
                svg {
                    max-width: 100%;
                }
            }
        }
        span {
            align-self: center;
            white-space: normal;
        }
    }
}
/*! purgecss end ignore */

