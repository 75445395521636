.image-container {
    &--rounded {
        border-radius: 6px;
    }
    &--bg-light {
        background-color: $secondary-lightest;
        padding: 10px;
    }
    img {
        width: 100%;
    }
}

.logo-preview-container {
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid $gray-500;
    align-self: center;
}