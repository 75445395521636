//
// Code
//

code {
    background-color: $code-bg;
    padding: $code-padding;
    font-weight: 400;
    color: $primary;
    @include border-radius($border-radius);
    span {
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
    }
}

.code-block {
    background-color: $code-bg;
    padding: 13px;
    border-radius: 6px;
}