.button-thumbnail-text {
	text-align: center;
	border: 1px solid #EAEDF2;
	display: block;
	border-radius: 6px;
	overflow: hidden;
	cursor: pointer;

	figure {
		margin: 0;

		img {
			width: 100%;;
		}
	}

	figcaption {
		border-top: 1px solid #EAEDF2;
		padding: 15px;

		h3 {
			font-size: 14px;
			margin: 0;
		}
	}

	&:hover {
		border-color: $primary;

		figcaption {
			border-color: $primary;
		}
	}
}