//
// Fullcalendar
//

// Base
@mixin fullcalendar-base() {
  .fc-base {
    --day-header-aspect-ratio: 5/2;
    width: 100%;

    // Popover
    .fc-popover {
      z-index: $z-popover;
    }

    // Event
    .fc-event {
      &-time {
        min-width: min-content;
      }
    }

    // Non-Business Days
    .fc-non-business {
      background: none;
    }

    // Day box header
    .fc-daygrid-day-top {
      flex-direction: row;
      aspect-ratio: var(--day-header-aspect-ratio);
    }
  }
}

@mixin fullcalendar-events() {
  .fc-event {
    &__item {
      color: $dark;
      cursor: pointer;
      overflow: hidden;

      &:hover {
        color: $dark;
      }
    }

    &__tooltip {
      display: none;
      position: fixed;
      background-color: #3f4254;
      color: $white;
      padding: 8px;
      border-radius: 4px;
      z-index: $z-tooltip;
      width: 150px;
      font-size: 10px;
      line-height: 15px;
    }

    &--email {
      background-color: #ed9e721a;
      border-color: #ed9e72;
      --icon-color: #ed9e72;
    }

    &--sms {
      background-color: #4bb2c41a;
      border-color: #4bb2c4;
      --icon-color: #4bb2c4;
    }

    &--viber {
      background-color: #7b72ed1a;
      border-color: #7b72ed;
      --icon-color: #7b72ed;
    }

    &--whatsapp {
      background-color: #ff00001a;
      border-color: #ff0000;
      --icon-color: #ff0000;
    }

    &--reminder {
      background-color: #ffffff;
      border-color: #ffffff;
      --icon-color: $orange;
    }

    &--match {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: #ffffff00;
      border-color: #ffffff00;
      --icon-color: #7e8299;
    }

    &--draft {
      background-color: #fff;
      border-style: dashed;
    }

    &--past {
      color: $gray-600;
    }
  }

  .fc-event-item {
    display: flex;
    gap: 4px;
    padding: 2px 6px;

    &__icon {
      --icon-color: inherit;
      font-size: 1rem;
      color: var(--icon-color, inherit);
    }

    &__time {
      min-width: min-content;
      font-weight: 700;
    }

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .fc-event-match {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    aspect-ratio: var(--day-header-aspect-ratio);
    gap: 4px;
    padding: 4px;
    padding-left: 20%;
    font-size: 0.6rem;

    &__opponent-logo-wrapper {
      height: 100%;
      aspect-ratio: 1/1;
    }
    &__info {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 4px;
      flex: 1;
    }
    &__score {
      font-weight: 700;
      font-size: 1.25em;

      &--lost {
        color: $danger;
      }
      &--win {
        color: $success;
      }
      &--draw {
        color: $gray-600;
      }
    }
    &__venue {
      padding: 2px 4px;
      background: $gray-100;
      border-radius: 1rem;
      font-weight: 500;
      font-size: 0.85em;
    }
    &__league-logo-wrapper {
      position: absolute;
      left: 0;
      bottom: 4px;
      height: 50%;
      aspect-ratio: 1/1;
      filter: grayscale(1);
    }
  }

  @include media-breakpoint-up(sm) {
    .fc-event-match {
      font-size: 0.7rem;
    }
  }
  @include media-breakpoint-up(md) {
    .fc-event-match {
      font-size: 0.8rem;
    }
  }
  @include media-breakpoint-up(lg) {
    .fc-event-match {
      font-size: 0.9rem;
    }
  }
  @include media-breakpoint-up(xl) {
    .fc-event-match {
      font-size: 1rem;
    }
  }
}

// Build
/*! purgecss start ignore */
@include fullcalendar-base();
@include fullcalendar-events();
/*! purgecss end ignore */
