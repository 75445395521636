/*! purgecss start ignore */
.badge-placement {
  $self: &;

  display: inline-block;
  position: relative;

  &__badge {
    position: absolute;

    /* Badge Positioning */

    &:where(#{$self}--bottom-center &) {
      left: 50%;
      top: 100%;
    }

    &:where(#{$self}--bottom-left &) {
      left: 0;
      top: 100%;
    }

    &:where(#{$self}--bottom-right &) {
      left: 100%;
      top: 100%;
    }

    &:where(#{$self}--middle-center &) {
      left: 50%;
      top: 50%;
    }

    &:where(#{$self}--middle-left &) {
      left: 0;
      top: 50%;
    }

    &:where(#{$self}--middle-right &) {
      left: 100%;
      top: 50%;
    }

    &:where(#{$self}--top-center &) {
      left: 50%;
      top: 0;
    }

    &:where(#{$self}--top-left &) {
      left: 0;
      top: 0;
    }

    &:where(#{$self}--top-right &) {
      left: 100%;
      top: 0;
    }

    /* Badge Anchor Position */

    &:where(#{$self}--anchor-bottom-center &) {
      transform: translate(-50%, -100%);
    }

    &:where(#{$self}--anchor-bottom-left &) {
      transform: translate(0, -100%);
    }

    &:where(#{$self}--anchor-bottom-right &) {
      transform: translate(-100%, -100%);
    }

    &:where(#{$self}--anchor-middle-center &) {
      transform: translate(-50%, -50%);
    }

    &:where(#{$self}--anchor-middle-left &) {
      transform: translate(0, -50%);
    }

    &:where(#{$self}--anchor-middle-right &) {
      transform: translate(-100%, -50%);
    }

    &:where(#{$self}--anchor-top-center &) {
      transform: translate(-50%, 0);
    }

    &:where(#{$self}--anchor-top-left &) {
      transform: translate(0, 0);
    }

    &:where(#{$self}--anchor-top-right &) {
      transform: translate(-100%, 0);
    }
  }

  &--disabled {
    #{$self}__badge {
      display: none;
    }
  }

  &--hover {
    > #{$self}__badge {
      display: none;
    }

    &:hover {
      > #{$self}__badge {
        display: block;
      }
    }
  }

  &--focus-within {
    #{$self}__badge {
      display: none;
    }

    &:focus-within {
      #{$self}__badge {
        display: block;
      }
    }
  }
}
/*! purgecss end ignore */
