/* Widgets Controls Selector */

.analytics-controls {
	padding: 20px;

	h2 {
		margin-bottom: 4px;
		font-size: 16px;
	}

	p {
		margin-bottom: 15px;
	}
	
	.button-thumbnail-text {
		margin-bottom: 25px;
	}
}