/*! purgecss start ignore */
.tooltip-wrapper {
  $self: &;

  --_anchor-x: 50%;
  --_anchor-y: 0%;
  position: relative;
  display: inline-block;

  &--enabled:hover > #{$self}__tooltip-container {
    visibility: visible;
    opacity: 1;
  }

  &--anchor {
    &-x {
      &-left {
        --_anchor-x: 0%;
      }
      &-center {
        --_anchor-x: 50%;
      }
      &-right {
        --_anchor-x: 100%;
      }
    }
    &-y {
      &-top {
        --_anchor-y: 0%;
      }
      &-center {
        --_anchor-y: 50%;
      }
      &-bottom {
        --_anchor-y: 100%;
      }
    }
  }
}
/*! purgecss end ignore */
