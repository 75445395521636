.sms-sendout {
  .card-header {
    position: relative;

    .badge {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  &__warning-badge  {
    position: absolute;
    top: -1px;
    right: 12px;
  }
}

.sms-preview {
  &__container {
    position: relative;
    max-width: 220px;
    margin: 0 auto;
  }
  &__phone {
    img {
      margin: 0 auto;
      width: 100%;
      max-width: 220px;
    }
  }
  
  &__message {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 60px 25px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__message-container{
    max-height: 100%;
    overflow-y: auto;
    padding-right: 15px;

    &::-webkit-scrollbar{
        width: 4px;
    }
    &::-webkit-scrollbar-track{
        background: none;
    }
  }

  &__message-content {
    overflow: hidden;
    padding: 12px;
    background-color: #3F4354;
    color: white;
    border-radius: 30px;
    border-bottom-left-radius: 0px;
    font-size: 10px;
    white-space: pre-line;
    word-wrap: break-word;
  }
}


.input-sms-text {  

    footer {
        display: flex;
        border: 1px solid $gray-300;
        border-top: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        & > div {
            padding: 5px 10px 7px 10px;
            flex-grow: 1;

            &:last-of-type {
                text-align: right;
            }
        }

        label,
        span {
            display: inline-block;
            font-size: 11px;
            margin-bottom: 0;
        }

        span {
            padding-top: 4px;
        }

        input {
            display: inline-block;
            width: 31px;
            -moz-appearance: textfield;
            text-align: center;
            padding: 3px;
            height: 25px;
            font-size: 11px;
            -webkit-appearance: none;
            -moz-appearance: textfield;
            margin: 0;
        }
    }
}

.sms-wizard-step {
  .card-body {
    min-height: 450px;
    display: grid;


  }
}
