/*! purgecss start ignore */
.steps {
    &--only-active {
        .step-item--disabled {
            display: none;
        }
    }
}

.step-item {
    align-items: center;
    display: grid;
    grid-column-gap: 16px;
    grid-template-areas: "marker title"
                         "none content";
    grid-template-columns: auto 1fr;

    &--disabled {
        opacity: .5;
    }

    &__marker {
        background: #EAEDF2;
        color: #7E8299;
        font-weight: 600;
        grid-area: marker;
        text-align: center;

        &--circle {
            aspect-ratio: 1/1;
            border-radius: 50%;    
        }

        &--xl {
            height: 32px;
            width: 32px;
            
            &.step-item__marker--text {
                line-height: 32px;    
            }
        }
    }

    &__title {
        grid-area: title;
    }

    &__content {
        grid-area: content;
    }
}
/*! purgecss end ignore */