// switch

// switch base
.switchbox {
  --circle-width: 0.75em;
  --white-space: calc((1em - var(--circle-width)) / 2);
  --width-ratio: 1.75;
  appearance: none;
  background: $gray-500;
  border: none;
  border-radius: 0.5em;
  box-sizing: border-box;
  cursor: pointer;
  flex-shrink: 0;
  font-size: $switch-size;
  height: 1em;
  position: relative;
  width: calc(var(--width-ratio) * 1em);

  &:before {
    aspect-ratio: 1/1;
    background: $white;
    border-radius: 50%;
    content: "";
    left: var(--white-space);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 120ms all ease-in-out;
    width: var(--circle-width);
  }

  &:where([data-toggled]) {
    background: $primary;

    &:before {
      left: calc(100% - var(--circle-width) - var(--white-space));
    }
  }

  &:where([data-toggle-disabled]) {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
