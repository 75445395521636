//
// Paginate
// TODO: (1) - review absolute/relative length units after type system refactoring is merged
//

.datatable-pager {
    align-items: center;
    display: flex;
    justify-content: space-between;
    
    &__nav {
        display: flex;
    }

    &__right {
        align-items: center;
        display: flex;
    }

    &__btn-arrow:is(button), 
    &__btn-number {
        border: 0;
        border-radius: $border-radius-sm;
        height: 26px; // TODO: (1)
        margin-left: 5px;
        padding: 0;
        width: 26px; // TODO: (1)
    }

    &__btn-arrow{
        &:is(button) {
            background-color: $gray-200;
            color: $gray-500;
            font-size: $font-size-sm;
        }

        &:active, &:hover {
            color: $gray-600;
        }

        &:disabled {
            background-color: $gray-100;
            color: $gray-400;
        }

        path {
            fill: currentColor !important; // TODO: remove important after icons (and its colors) refactoring 
        }
    }

    &__btn-arrow:first-child {
        margin-left: 0;
    }

    &__btn-number {
        background-color: $white;
        color: $gray-600;
        font-size: $font-size-xs;
        font-weight: $font-weight-bolder;
        padding-top: 3px; // icon/number alignment -> TODO: review after icon font created
    
        &--current,
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }

    &__dropdown {
        .dropdown-toggle {
            background: $gray-100;
            border-radius: $border-radius;
            padding: 5.5px 35px 5.5px 12px; // TODO: (1)

            &::after {
                right: 11px; // TODO: (1)
            }
        }
    }

    &__info {
        font-size: $font-size-sm;
        margin-left: 1em; // TODO: (1)
    }
    
    & i {
        color: inherit;
        font-size: inherit;
    }
}