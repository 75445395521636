// Progress
// "--color" represents color of filled value of progressbar
// "--bg-color" represents progressbar background color
// default view of progressbar is rounded sides so there is added "--height"
// ::-moz-progress-bar{bg-color} equivalent to ::-webkit-progress-value{bg-color}
// progress{bg-color} equivalent to ::-webkit-progress-bar{bg-color}

progress {
    --color: unset;
    --bg-color: unset;
    --height: 0.5rem;
    appearance: none;
    background-color: var(
        --bg-color,
        $gray-200
    ); // for mozilla browser background color
    border: none; // for mozilla browser background color
    border-radius: calc(var(--height) / 2);
    height: var(--height);
    overflow: hidden;
    width: 100%;
}

::-moz-progress-bar {
    background-color: var(--color, $primary); // for mozilla browser value color
}

::-webkit-progress-bar {
    background-color: var(
        --bg-color,
        $gray-200
    ); // for other browsers background color
}
::-webkit-progress-value {
    background-color: var(--color, $primary); // for other browsers value color
}
