.grid {

   width: 100%;
   border: 1px solid $gray-200;
   border-radius: 6px;
   padding: 12px;
  /**
   * User input values.
   */
   --grid-layout-gap: 12px;
   --grid-item--max-width: 150px;
   --grid-item--max-height: 120px;
 
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--max-width)), 1fr));
   grid-gap: var(--grid-layout-gap);
   grid-auto-rows: var(--grid-item--max-height);

}


