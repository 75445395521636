//
//  Floater
//


/*! purgecss start ignore */
.floater {
    position: absolute;
    // visibility: hidden;
    display: none;
    will-change: left, top;
    z-index: 3;

    &--active {
    //   visibility: initial;
      display: block;
    }

    &--fixed {
      position: fixed;
    }

    &__trigger {
      cursor: pointer;
    }
  }
/*! purgecss end ignore */