.tab-pane {
    display: none;
    
    &--scrollable {
        overflow-x: auto;
    }
    
    &--active {
        display: block;
    }
}
