/*! purgecss start ignore */
.confirm {
    $self: &;
    display: none;

    &--open {
        display: block;
    }

    &--no-overlay,
    &--has-overlay &__dialog {
      background: $white;
      border-radius: $border-radius;
      box-shadow: 4px 4px 34px rgba(0, 0, 0, 0.05);
      left: 50%;
      max-width: 455px;
      min-width: 265px;
      padding: 30px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;
    }

    &--fixed {
        position: fixed;
        z-index: $z-modal;

        &#{$self}--has-overlay {
            height: 100vh;
            left: 0;
            top: 0;
            width: 100vw;
        }
    }

    &__wrapper {
        position: relative;
    }

    &__overlay {
        background-color: rgba($black, 0.7);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__close {
        --icon-size: 0.6em;
        --icon-stroke: 2px;
        background-color: $gray-200;
        border-radius: 6px;
        color: $gray-500;
        cursor: pointer;
        height: 2em;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 2em;

        &-icon:before,
        &-icon:after {
            background: currentColor;
            content: '';
            display: inline-block;
            height: var(--icon-size);
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            transform-origin: center;
            width: var(--icon-stroke);
        }

        &-icon:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &__title {
        font-size: 24px;
        font-weight: $font-weight-bolder;

        & ~ .confirm__message {
            margin-top: 12px;
        }
    }

    &__message {
        font-size: $font-size-lg;
    }

    &__btn-cnt {
        align-items: center;
        display: flex;
        justify-content: end;
        gap: 20px;
        margin-top: 15px;
    }

    &__btn-primary {
        @extend .button;
        @extend .button--primary;
    }

    &__btn-secondary {
        @extend .button;
        @extend .button--secondary;
    }
}
/*! purgecss end ignore */
