.profiles-card {
  --_card-padding: 1rem;
  --_card-margin: 0;
  --_white-space: nowrap;
  --_main-font-size: 1.25rem;
  --_main-font-weight: 400;
  --_main-white-space: var(--_white-space);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &--triggered-action {
    --_main-font-size: 1rem;
    --_main-font-weight: 500;
    --_main-white-space: normal;
  }

  &__title {
    font-weight: $font-weight-bolder;
    font-size: $font-size-lg;
  }

  &__content {
    border: 1px solid $border-color;
    border-radius: $border-radius;
  }

  &__main-profiles-list {
    --number-font-size: 1.8rem;
    --border-weight: 0;
    white-space: var(--_main-white-space);
    padding: var(--_card-padding);
    margin: var(--_card-margin);
    list-style: none;
    font-size: var(--_main-font-size);
    font-weight: var(--_main-font-weight);
    color: $black;
  }

  &__profiles-info {
    padding: var(--_card-padding);
    margin: var(--_card-margin);
    color: $black;
    border-top: 1px solid $border-color;

    &:first-child {
      border-top: none;
    }
  }

  &__auxiliary-profiles-list {
    --number-font-size: 1.1rem;
    --border-weight: 1px;
    border-top: 1px solid $border-color;
    padding: var(--_card-padding);
    margin: var(--_card-margin);
    list-style: none;
    font-size: 1.1rem;
    color: $gray-600;
    white-space: var(--_white-space);

    &:first-child {
      border-top: none;
    }
  }

  &__target-segment-list {
    border-top: 1px solid $border-color;
    padding: var(--_card-padding);
    margin: var(--_card-margin);
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    white-space: var(--_white-space);

    &:first-child {
      border-top: none;
    }
  }
}
