//
// GridStack items
//

.grid-stack-item {
    &__inner {
        height: 100%;
    }

    .card {
        overflow: hidden;
    }
}
