//
// File Upload Panel Blazor Component
//

.generic-card  {
  padding: 1rem 1.25rem;
  background: $gray-100;
  border: 1px dashed $gray-300;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  width: 100%;

  &--extended {
    padding: 34px 110px;
  }

  &--hoverable-gray {
    background-color: $gray-100;
    color: $gray-500;

    &:hover {
      border: $border-width solid $black;
      color: $black;
    }
  }
}
