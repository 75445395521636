.grid-view-card {
    display: flex;
    height: 100%;
    .card {
        width: 100%;
    }
    .card-body {
        padding: 17px 20px 20px;
    }
    [class*="col-"] {
        display: grid;
        grid-auto-rows: auto fit-content(200px) auto;
        &:first-of-type {
            padding-right: 8px;
        }
        &:last-of-type {
            padding-left: 8px;
        }
    }
    &__actions {
        display: flex;
        align-self: end;
    }
    &__badge {
        position: absolute;
        top: 8px;
        right: 7px;
    }
    p {
        font-size: 10px;
        line-height: 14px;
    }
    .thumbnail {
        align-self: end;
    }
    a {
        color: #3F4254;
        :hover{
            cursor: grab;
            color: $orange;
        }
    }
}
