//
// Shapes
//

@each $size, $value in $shape-sizes {
  .shape-#{$size} {
    height: $value !important;
    width: $value !important;

    &--text {
      line-height: $value;
    }
  }

  .shape-y-#{$size} {
    height: $value !important;

    &--text {
      line-height: $value;
    }
  }

  .shape-x-#{$size} {
    width: $value !important;
  }
}

.shape {
  overflow: hidden;

  &-circle {
    aspect-ratio: 1;
    border-radius: 50%;
  }

  &-square {
    aspect-ratio: 1;
  }

  &-rounded-rect {
    border-radius: $border-radius;
  }
}

