//TODO:Tooltip called tooltip-container because of the bootsrap tooltip component
//     It should be renamed to tooltip in the future as well as the Tooltip component
//     ClassName in `src/libs/DataTalks.Web.Components/DataTalksUI/Components/Atoms/Tooltip`

/*! purgecss start ignore */
.tooltip-container {
  $self: &;
  --anchor-x: var(--_anchor-x, 50%);
  --anchor-y: var(--_anchor-y, 0%);
  --translate-x: 50%;
  --translate-y: 100%;
  --icon-size: 8px;
  --icon-ratio: 2;
  --icon-path: 0 0, 50% 100%, 100% 0;
  --border-radius: 0.42rem;
  --content-padding: 0 0 0 0;
  --content-radius-top-left: var(--border-radius);
  --content-radius-top-right: var(--border-radius);
  --content-radius-bottom-left: var(--border-radius);
  --content-radius-bottom-right: var(--border-radius);
  --bg: #3f4254;

  position: absolute;
  top: var(--anchor-y);
  left: var(--anchor-x);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  translate: calc(0% - var(--translate-x)) calc(0% - var(--translate-y));
  padding: var(--content-padding);
  z-index: $z-tooltip;

  // Positioning modifiers
  &--translate {
    &-x {
      &-left {
        --translate-x: 0%;
      }
      &-center {
        --translate-x: 50%;
      }
      &-right {
        --translate-x: 100%;
      }
    }
    &-y {
      &-top {
        --translate-y: 0%;
        --content-padding: calc(var(--icon-size) - 1px) 0 0 0;
        &:where(#{$self}--translate-x-left) {
          --content-radius-top-left: 0;
          --icon-path: 0 0, 0 100%, 100% 100%;
        }
        &:where(#{$self}--translate-x-center) {
          --icon-path: 50% 0, 0 100%, 100% 100%;
        }
        &:where(#{$self}--translate-x-right) {
          --content-radius-top-right: 0;
          --icon-path: 100% 0, 0 100%, 100% 100%;
        }
      }
      &-center {
        --translate-y: 50%;
        --icon-size-inverse: calc(var(--icon-size) * var(--icon-ratio));
        --icon-ratio-inverse: calc(1 / var(--icon-ratio));
        &:where(#{$self}--translate-x-left) {
          --content-padding: 0 0 0 calc(var(--icon-size) - 1px);
          --icon-path: 0 50%, 100% 0, 100% 100%;
        }
        &:where(#{$self}--translate-x-right) {
          --content-padding: 0 calc(var(--icon-size) - 1px) 0 0;
          --icon-path: 0 0, 0 100%, 100% 50%;
        }
      }
      &-bottom {
        --translate-y: 100%;
        --content-padding: 0 0 calc(var(--icon-size) - 1px) 0;
        &:where(#{$self}--translate-x-left) {
          --content-radius-bottom-left: 0;
          --icon-path: 0 0, 0 100%, 100% 0;
        }
        &:where(#{$self}--translate-x-center) {
          --icon-path: 0 0, 50% 100%, 100% 0;
        }
        &:where(#{$self}--translate-x-right) {
          --content-radius-bottom-right: 0;
          --icon-path: 0 0, 100% 100%, 100% 0;
        }
      }
    }
  }

  // Size modifiers
  &--small {
    max-width: 165px;
  }
  &--medium {
    max-width: 220px;
  }
  &--large {
    max-width: 330px;
  }
  &--extra {
    max-width: 550px;
  }

  &:before {
    background-color: var(--bg);
    position: absolute;
    top: var(--translate-y);
    left: var(--translate-x);
    transform: translate(
      calc(0% - var(--translate-x)),
      calc(0% - var(--translate-y))
    );
    height: var(--icon-size-inverse, var(--icon-size));
    aspect-ratio: var(--icon-ratio-inverse, var(--icon-ratio));
    clip-path: polygon(var(--icon-path));
    content: "";
  }

  &__content {
    background-color: var(--bg);
    border-radius: var(--content-radius-top-left)
      var(--content-radius-top-right) var(--content-radius-bottom-right)
      var(--content-radius-bottom-left);
    color: $white;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    max-width: 220px;
    padding: 0.75rem;
    width: max-content;
    white-space: normal;
  }
}
/*! purgecss end ignore */
