//
// Go Back section
//

.go-back-section {
    align-items: center;
    display: flex;

    &__right {
        margin-left: 15px;
    }

    &__title {
        font-size: 14px;
        font-weight: $font-weight-bolder;
        line-height: 15px;
        margin-bottom: 0;
    }

    &__subtitle {
        color: $gray-800;
        font-size: $font-size-sm;
        line-height: 15px;
        margin-bottom: 0;
        margin-top: 5px;
        font-weight: $font-weight-bold;
    }
}
