//
// FlatPickr
//

/*! purgecss start ignore */

.flatpickr-calendar {
	max-width: 265px;
	width: auto;

    &:after,
    &:before { //remove arrow (triangle) from floater
        content: none;
    }

	.flatpickr-months {
		.flatpickr-month,
		.flatpickr-current-month {
			height: 46px;
		}

		.flatpickr-next-month,
		.flatpickr-prev-month {
			padding: 10px 13px;
		}

		.flatpickr-monthDropdown-months,
		.numInputWrapper {
			font-size: 13px;
			font-weight: 500;
			padding: 5px 10px;
			border-radius: 6px;
			&:hover {
				background-color: $gray-100;
			}
		}

		.flatpickr-monthDropdown-months {
			.flatpickr-monthDropdown-month {
				font-weight: 400;
			}
		}

		.numInputWrapper {
			width: 7ch;
			.arrowUp,
			.arrowDown {
				border: none !important;
				&:after {
					content: "";
				}
			}
		}
	}

	.flatpickr-weekdays {
		height: 26px;
		padding: 0 6px;
	}

	.flatpickr-days {
		width: 265px;

		.dayContainer {
			width: 265px;
			min-width: 265px;
			max-width: 265px;
			padding: 6px;
		}

		.flatpickr-day {
			position: relative;
			height: 36px;
			line-height: 35px;
			color: $gray-800;

			&:before {
				content: "";
				display: block;
				height: 30px;
				width: 30px;
				position: absolute;
				border-radius: 6px;
				left: 50%;
				top: 50%;
				margin: -15px 0 0 -15px;
				z-index: -1;
			}

			&.today {
				border: none;
				background-color: transparent;

				&:before {
					background-color: $gray-100;
				}
			}

			&.prevMonthDay {
				opacity: 0.5;
			}

			&.selected {
				border: none;
				background-color: transparent;
				color: white;

				&:before {
					background-color: $primary;
				}
			}

			&:hover {
				background-color: transparent;
				border: none;
				color: white;

				&:before {
					background-color: $primary;
				}
			}
		}
	}

    .flatpickr-time {
        span.arrowUp,
        span.arrowDown {
            border: none;
        }

        .flatpickr-am-pm,
        input {
            &:hover {
                background-color: $gray-100;
            }
        }
    }

    &.noCalendar.hasTime .flatpickr-time {
        border-top: none;
        height: 40px;
        padding: 5px 48px;

        .numInputWrapper {
            --arrow-size: 4px;

            flex: 0 0 auto;
            float: none;
            height: 100%;
            position: relative;
            width: auto;

            &:hover {
                background: transparent;
            }
        }

        input {
            background: $gray-100;
            border-radius: 3px;
            color: $black;
            display: block;
            height: 30px;
            position: static;
            padding-left: 5px;
            text-align: left;
            width: 48px;

            &.flatpickr-hour {
                font-weight: 400;
            }

            &:focus {
                background: $gray-100;
                border: 1px solid $orange;
            }

            &:hover {
                background: $gray-100;
            }
        }

        .arrowUp, 
        .arrowDown {
            background: transparent; 
            border: none;
            border-radius: 2px; 
            color: $white; 
            opacity: 1; 
            padding: 2px;

            &:is(.arrowDown) {
                bottom: 0;
                top: unset;
            }

            &:before {
                background: #2F80ED; 
                border-radius: 2px; 
                display: inline-block;
                color: $white; 
                content: ""; 
                height: 100%;
                width: 100%;
            }

            &:after {
                border: 1px solid #fff;
                border-left-color: transparent;
                border-bottom-color: transparent;
                border-radius: 1px;
                height: var(--arrow-size, 5px);
                left: 50%;
                width: var(--arrow-size, 5px);
                transform: translate(-50%, -25%) rotate(315deg);
                top: 50%;
            }

            &:is(.arrowDown):after {
                transform: translate(-50%, -75%) rotate(135deg);
            }

            &:hover {
                background: transparent;
            }
        }

        .flatpickr-time-separator {
            font-weight: 400;
            margin: 0 5px;
        }

        .flatpickr-am-pm {
            border-radius: 3px;
            height: 30px;
            line-height: 30px;
            margin-left: 5px;
            padding: 0 12px;
            width: auto;

            &:hover {
                background: $gray-100;
            }
        }
    }
}

/*! purgecss end ignore */
