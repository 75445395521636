//
// Content Repository's Tile component
//

.tile {
    height: 100%;
    width: 100%;
    border: 2px solid transparent;
    border-radius: 6px;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        border: 1px solid $gray-200;
        border-radius: 6px;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
    }

    &--active {
        border: 2px solid $primary;

        &::before {
            display: none;
        }
    }

    &--icon {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &--clickable {
        cursor: pointer;
    }

    &__img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: inherit;
        background-image: url('/media/pages/contentLibrary/transparent-background.jpg');
        background-size: cover;
    }

    &__checkbox {
        font-size: 1.4em;
        position: absolute;
        right: 0;
        top: 0;
        margin: 0.25rem;

        &:disabled {
            pointer-events: none;

            &:checked {
                background: $primary;
            }
        }
    }

    &__icon {
        font-size: 3rem;
    }
}
