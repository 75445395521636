.filter-options-view {
  $self: &;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  &__title {
    font-weight: 600;
    color: $dark-75;
  }

  &__body {
    display: flex;
    flex-grow: 1;
    gap: 1.5rem;
  }

  &__filter {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 100px;
    width: 100%;
  }

  &__clear {
    &-title {
      display: block;
    }
    &-icon {
      display: none;
    }
  }

  &__radio {
    &-list {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 0.5rem;
      padding: 0.25rem 0;
      cursor: pointer;
    }
  }

  &__date {
    &-form {
      cursor: pointer;
      overflow: hidden;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 0.75rem;
    }

    &-icon {
      color: $text-muted;
      font-size: get($icon-sizes, lg);
    }

    &-title {
      flex-grow: 1;
    }
  }

  &__range-slider {
    min-width: 100px;
    padding-top: 2rem;
  }

  :where(.grid-stack-item--sm &) {
    #{$self} {
      &__body {
        gap: 1rem;
      }
      &__clear {
        &-button {
          padding: 0.65rem;
          min-height: auto;
        }
        &-title {
          display: none;
        }
        &-icon {
          display: inline-flex;
          font-size: get($icon-sizes, lg);
        }
      }
    }
  }

  :where(.grid-stack-item--xxs &, .grid-stack-item--xs &) {
    #{$self} {
      &__body {
        gap: 1rem;
      }
      &__clear {
        position: absolute;
        top: 0;
        right: 0;

        &-button {
          padding: 0.1rem;
          min-height: auto;
        }
        &-title {
          display: none;
        }
        &-icon {
          display: inline-flex;
          font-size: get($icon-sizes, md);
        }
      }
    }
  }
}
