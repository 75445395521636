//
// Content Repository's Search Menu
//

.search-menu {
  $self: &;
  background-color: $white;
  border: $border-width solid $gray-200;
  border-radius: $border-radius-lg;
  width: 450px;

  // Labels
  &__label {
    @extend .content-repository__label;
    color: $gray-600;
  }

  &__button {
    @extend .content-repository__button;
    min-width: unset;

    &-cancel {
      margin-right: auto;
    }
  }

  // Sections
  &__section {
    padding: 12px;

    &:not(:first-child) {
      border-top: $border-width solid $gray-200;
    }
  }

  &__section-title {
    font-size: $font-size-sm;
    font-weight: $font-weight-bolder;
    margin-bottom: 0;
  }

  &__section-content {
    display: flex;
    gap: 4px;

    &:where(#{$self}__section-title + #{$self}__section-content) {
      margin-top: 3.5px;
    }
  }

  //Checkbox With Label
  &__checkbox-w-label {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  //Tags section's values container
  &__tags-values {
    .typeahead-container {
      width: 100%;
    }
  }

  //Type section's values container
  &__type-values {
    align-items: center;
    display: grid;
    grid-gap: 12px 30px;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 12px;
  }

  //Buttons container
  &__btn-wrapper {
    display: flex;
    justify-content: end;
    gap: 12px;
  }

  &__background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
