.password-input {
  display: flex;
  position: relative;

  &__input {
    flex: 1;

    &:focus {
      border-color: $primary;
    }
  }

  &__button {
    aspect-ratio: 1;
    position: absolute;
    right: 4px;
    top: 4px;
    border: none;
    background: none;
    color: $secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 32px;
    border-radius: $border-radius;

    &:focus-visible,
    &:hover {
      background-color: $secondary-lightest;
    }
  }

  &__icon {
    font-size: 1.5rem;
  }
}
