.shorten-link-panel {
  padding: 16px 20px 20px;
  width: 100%;

  & > div {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    & > div {
      display: grid;
      grid-template-columns: 1fr auto;
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &__input-container {
    margin-right: 15px;

    input,
    textarea {
      margin-bottom: 15px;
    }

    .btn {
      float: right;
    }
  }

  &__input-link {
    min-height: 40px;
    height: auto;
    resize: none;
  }

  &__input-display {
    border: 1px dashed $secondary;
    pointer-events: none;
  }
}