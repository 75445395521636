//
// Images
//

img {
    max-width: 100%;
}

.img {
    &--contain {
        height: 100%;
        object-fit: contain;
        width: 100%;
    }

    &--cover {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &--fill {
        height: 100%;
        object-fit: fill;
        width: 100%;
    }

    &--scale-down {
        height: 100%;
        object-fit: scale-down;
        width: 100%;
    }
}
