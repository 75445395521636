/*! purgecss start ignore */
.accordion-panel {
  $self: &;
  background-color: rgba($light, (0.5));
  border-radius: $border-radius;
  border-color: $gray-300;
  border-style: solid;
  border-width: 1px;
  width: 100%;
  overflow: hidden;

  &__trigger {
    align-items: center;
    background-color: $light;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    gap: 1.5rem;
  }

  &__title {
    font-size: $h4-font-size;
    font-weight: $font-weight-bolder;
  }

  &__trigger-icon {
    font-size: 1.75rem;
    transition: transform 0.3s;

    &--up {
      transform: rotateX(180deg);
    }
  }

  &__content-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s;

    &-row {
      overflow: hidden;
    }

    &--open {
      grid-template-rows: 1fr;
    }
  }

  &__content {
    border-top: 1px solid $gray-300;
  }

  @include icon-reset;
}
/*! purgecss end ignore */
