@mixin icon-reset {
    & .svg-icon {
        & svg { // TODO: delete after icon refactoring;
            height: 1em;
            width: 1em;
    
            & path { // TODO: delete after icon refactoring;
                fill: currentColor !important;
            }
        }
    }
}