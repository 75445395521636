@mixin scrollbar(
  $size: 10px,
  $foreground-color: #999,
  $background-color: #333,
  $thumb-radius: 6px,
  $track-radius: 0
) {
    @supports not selector(::-webkit-scrollbar) {
        scrollbar-color: $foreground-color $background-color;
        @if $size == 0 {
            scrollbar-width: none;
        } @else if $size < 8px {
            scrollbar-width: thin;
        } @else {
            scrollbar-width: auto;
        }
    }
    
    &::-webkit-scrollbar {
        height: $size;
        width: $size;
    }
    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: $thumb-radius;
    }
    &::-webkit-scrollbar-track {
        background: $background-color;
        border-radius: $track-radius;
    }
}

@mixin scrollbar-inside {

    overflow-y: overlay;

    
    @include scrollbar(
        $size: 6px,
        $foreground-color: whitespace-with-gradient-background(
            $color: rgba($gray-400,0.4), 
            $degree: 90deg, 
            $start: 20%, 
            $end: 80%
        ),
        $background-color: transparent
    )
}
