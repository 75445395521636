//
// Template Selector Modal


.template-selector-module {
    input[type="radio"] {
        position: absolute;
        top: 0px;
        left: 15px;
        width: calc(100% - 30px);
        height: 100%;
        opacity: 0;
        z-index: 2;

        &:checked {
            & ~ div {
                border-color: $primary;
                footer {
                    border-color: $primary;
                    div {
                        background-color: $primary;
                        border-color: $primary;
                        span {
                            opacity: 1;
                            svg {
                                g {
                                    [fill] {
                                        fill: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    & > div {
        border: 1px solid $gray;
        border-radius: 6px;
        overflow: hidden;
        height: 100%;
        position: relative;
        display: grid;

        & > div {
            padding: 5px;
        }
    }
    footer {
        border-top: 1px solid $gray;
        padding: 15px 15px;
        min-height: 45px;
        align-self: end;
        width: 100%;
        text-align: left;
        position: relative;

        strong {
            overflow: hidden;
            width: calc(100% - 20px);
            display: block;
        }
        
        div {
            border: 1px solid $gray-400;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            position: absolute;
            top: 15px;
            right: 15px;
            span {
                opacity: 0;
                position: relative;
                top: -3px;
                svg {
                    g [fill] {
                        fill: white;
                    }
                }
            }
        }
    }
}

#selectTemplate {
    .modal-body {
        padding-left: 65px;
        padding-right: 65px;
    }
    .modal-footer {
        border-top: none;
        padding-left: 105px;
        padding-right: 105px;
        button {
            margin: 0;
        }
    }
}


#carouselTemplates {
    margin-bottom: 15px;
    padding: 0 65px;
    .carousel-control-prev {
        left: 0px;
    }
    .carousel-control-next {
        right: 0px;
    }
}

.carousel-indicators {
    margin-bottom: 0;
    bottom: -35px;
    li {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid $gray-600;
        margin: 0 5px;
        &.active {
            background-color: $gray-600;
        }
    }
}

.carousel-control-prev,
.carousel-control-next {
    z-index: 2;
    width: auto;
}
.carousel-control-prev {
    left: 25px;
}
.carousel-control-next {
    right: 25px;
}