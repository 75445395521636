.loading-wrapper {
  $self: &;
  position: relative;

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--loading {
    #{$self}__content {
      display: none;
    }
  }

  &--transparent {
    #{$self}__content {
      display: block;
      opacity: 0.5;
    }
  }
}
