/**
 * Blends two colors together with a specified opacity.
 *
 * @function blend-colors
 * @param {Color} $background - The background color.
 * @param {Color} $overlay - The overlay color.
 * @param {Number} $opacity - The opacity of the overlay color (between 0 and 1).
 * @return {Color} - The resulting blended color.
 */
@function blend-colors($background, $overlay, $opacity) {
  $r: red($background) * (1 - $opacity) + red($overlay) * $opacity;
  $g: green($background) * (1 - $opacity) + green($overlay) * $opacity;
  $b: blue($background) * (1 - $opacity) + blue($overlay) * $opacity;

  @return rgb($r, $g, $b);
}
