//
// SVG Icon
//


@mixin svg-icon-color($color, $important: false) {
    svg {
        g {
            [fill] {
                transition: fill 0.3s ease;
                &:not([fill="#ffffff"]) {
                    fill: $color valueif($important, !important, null);
                }
                &[fill="#ffffff"] {
                    fill: #ffffff !important;
                }
            }
            [stroke] {
                transition: stroke 0.3s ease;
                &:not([stroke="#ffffff"]) {
                    stroke: $color valueif($important, !important, null);
                }
                &[stroke="#ffffff"] {
                    stroke: #ffffff !important;
                }
            }
        }

        &:hover {
            g {
                [fill] {
                    transition: fill 0.3s ease;
                }
                [stroke] {
                    transition: stroke 0.3s ease;
                }
            }
        }
    }
}

@mixin svg-icon-transition() {
    svg {
        g {
            [fill] {
                transition: fill 0.3s ease;
            }
        }
    }
}

@mixin svg-icon-size($size, $important: false) {
    svg {
        height: $size valueif($important, !important, null);
        width: $size valueif($important, !important, null);
    }
}
