//
// Inside
//
// Example: 
// @include isChildOf(.icon, .table th) {};
// will compile: 
// .icon:where(.table th .icon) {}
//

@mixin isChildOf($selector, $parentSelector) {
    #{$selector}:where(#{$parentSelector} #{$selector}) {
      @content;
    }
}
