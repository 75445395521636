//
// SVG Icon
//

/*! purgecss start ignore */
.svg-icon {
    @include svg-icon-size(get($svg-icon-sizes, md));
    @include svg-icon-color($text-muted);

    // Theme colors
    @each $name, $color in $theme-text-colors  {
        &.svg-icon-#{$name} {
            @include svg-icon-color($color, true);
        }
    }
    
    // Responsive icon sizes
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
            
            // Sizes
            @each $name, $value in $svg-icon-sizes  {
                &.svg-icon#{$infix}-#{$name} {
                    @include svg-icon-size($value, true);
                }
            }
        }
    }

    &.svg-icon--center-vertically {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center; 
    }

    &--font {
        svg {
            height: 1em;
            width: 1em;
    
            path {
                fill: currentColor !important;
            }
        }
    }

    &__secondary-path {
        opacity: .3;
    }
}
/*! purgecss end ignore */
