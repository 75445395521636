.toggleable-card {
    $self: &;
    background-color: $white;
    border: $border-width solid $gray-200;
    border-radius: $border-radius;
    padding: 12px;
    width: 100%;

    &:not(#{&}--disabled):not(#{&}--active):hover {
        background-color: $gray-100;
        border-color: $gray-500;
        cursor: pointer;
    }

    &--active {
        border-color: $primary;
    }

    &--disabled {
        cursor: not-allowed;
    }

    &--has-checkbox {
        align-items: center;
        display: flex;

        #{$self}__checkbox + * {
            margin-left: 11px;
        }
    }
}