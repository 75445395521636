//
// Badges
//


// Base

/*! purgecss start ignore */
.badge {
	background-color: $primary;
	background-color: var(--bg-color, $primary);
	position: relative;

	&-rounded {
		border-radius: 30px;
	}

	&--circle {
		border-radius: 50%;
	}

	&__inner {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&--font {
		height: 1.65em;
		line-height: 1.65em;
		width: 1.65em;
	}

	&-info {
		background-color: $gray-100;
		color: $primary;
	}
	&-success {
		background-color: rgba($success, 0.4);
		color: $white;
	}
	&-gray {
		color: $gray-600;
		background-color: $gray-300;
	}
	&-gray-outline {
		color: $gray-600;
		background-color: $white;
		border: 1px solid $gray-600;
	}

	&-warning {
		background-color: $warning;
		color: $white;
	}

	&-danger {
		background-color: $danger;
		color: $white;
	}

	&-secondary {
		background-color: #6993FF;
		color: $white;
	}

	&-alert {
		background-color: $primary-lightest;
		color: $primary;
	}

	&-available {
		background-color: $success;
		color: $white;
	}
}

/*! purgecss end ignore */