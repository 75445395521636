//
// Modal
//

.modal {
    overflow: auto;
    
    // Header
    .modal-header {
        align-items: center;
        position: relative;

        .modal-title {
            font-weight: $modal-title-font-weight;
            font-size: $modal-title-font-size;
            color: $modal-title-color;

            small {
                font-weight: $modal-title-small-font-weight;
                font-size: $modal-title-small-font-size;
                color: $modal-title-small-color;
            }
        }

        .close {
            outline:none !important;
            color: $modal-title-close-color;
            transition: $transition-link;
            padding: 0;
            margin: 0;
            line-height: 0;
            font-size: 1rem !important;
            position: absolute;
            top: 10px;
            right: 10px;
            display: block;

            i,
            .ki {
                transition: $transition-link;
                color: $dark-75;
                font-size: 0.8rem;
            }

            span {
            }

            &:hover {
                transition: $transition-link;
                color: $modal-title-close-hover-color;

                i,
                .ki {
                    transition: $transition-link;
                    color: $primary;
                }
            }
        }
    }

    &.modal-delete {
        .modal-header {
            padding-bottom: 10px;
        }
        .modal-body {
            padding-right: 80px;
            padding-top: 0px;
            padding-bottom: 0px;
        }
        .modal-footer {
            padding-top: 10px;
        }
    }

    &.modal-confirm {
        .modal-dialog {
            &.modal-success {
                .modal-content {
                    border-color: $success;
                    background-color: rgba($success-lightest, 1);
                }
            }
            &.modal-warning {
                .modal-content {
                    border-color: $warning;
                    background-color: rgba($warning-lightest, 1);
                }
            }
            &.modal-alert {
                .modal-content {
                    border-color: $danger;
                    background-color: rgba($danger-lightest, 1);
                }
            }
            .modal-content {
                border: 1px solid $gray;
                text-align: center;
                .modal-body {
                    padding: 2rem 2rem;
                }
                .modal-title {
                    line-height: 1.2;
                    font-weight: 600;
                }
            }
        }
    }

    // Sticky Modal
    &.modal-sticky {
        &.modal-sticky-bottom-right {
            $sticky-width: 500px;
            $sticky-width-lg: 600px;

            padding: 0 !important;
            max-width: $sticky-width;

            height: auto;
            position: fixed;
            left: auto;
            top: auto;
            bottom: 25px;
            right: 25px;
            margin: 0;
            box-shadow: 0px 0px 60px -15px rgba(0, 0, 0, 0.2);
            @include border-radius($border-radius);

            .modal-dialog {
                position: static;
                max-width: $sticky-width;
                width: auto;
                margin: 0;
                @include border-radius($border-radius);

                .modal-content {
                    border: 0;
                    @include border-radius($border-radius);
                }
            }

            &.modal-sticky-lg {
                max-width: $sticky-width-lg;

                .modal-dialog {
                    max-width: $sticky-width-lg;
                }
            }

            // Tablet & Mobile Modess
            @include media-breakpoint-down(md) {
                bottom: 10px;
                right: 10px;
            }

            // Mobile mode
            @include media-breakpoint-down(sm) {
                max-width: 90%;
            }
        }
    }
}

// Open state
.modal-open {
    overflow: auto !important;
    padding: 0 !important;
}

// Responsive breakpoints
@include media-breakpoint-up(xl) {
    .modal-dialog.modal-xl {
        max-width: map-get($grid-breakpoints, xl) - 100px;
    }
}
