// 
// Notifications
//

.notifications{
    &__balloon-button { //check $self usage and replace this
        font-size: 22px;
    }

    &--unread {
        .notifications__balloon-button { //check $self usage and replace this 
            box-sizing: content-box;
            padding-right: 22px; // TODO: (1)    font-size: 22px;
        }
    }

    &__badge {
        line-height: 1;
        margin-right: 10px; // TODO: (1)
    }
}
