//
// Variables for buttons
//

// Border Radius
$button-radius: 6px;
// Font Size
$button-font-size: 14px;
// Font Weight
$button-font-weight: 600;
// Height
$button-height: $input-height;
// Padding
$button-base-padding: 0 14px;
