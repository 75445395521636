.sp {
  $self: &;

  &-hue {
    border: 1px solid #333;
    cursor: row-resize;
    left: 90%;
  }

  &-dragger {
    background: transparent;
    box-shadow: 0 0 0 1px #111;
    cursor: crosshair;
  }

  &-alpha-handle {
    background-color: #ccc;
    border: 1px solid #555;
    cursor: col-resize;
    width: 4px;
  }

  &-alpha-inner {
    cursor: col-resize;
  }

  &-color {
    border: 1px solid #333;
    cursor: crosshair;
    right: 15%;
  }

  &-container {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
    border-radius: 3px;

    button,
    button:hover,
    button:active {
      background: rgba(0, 0, 0, 0.2);
      border-color: rgba(0, 0, 0, 0.2);
      color: #ddd;
      text-shadow: none;
      box-shadow: none;
      padding: 3px 5px;
    }
  }

  &-picker-container {
    border: none;
  }

  &-cancel,
  &-cancel:hover {
    bottom: -8px;
    color: #777 !important;
    font-size: 25px;
    left: 0;
    position: absolute;
    text-decoration: none;
  }

  &-slider {
    background-color: #ccc;
    border: 1px solid #555;
    cursor: row-resize;
    height: 3px;
    left: -4px;
    width: 22px;
  }

  &-button-container {
    float: none;
    width: 100%;
    position: relative;
    text-align: right;
  }

  &-palette {
    &-container {
      border: none;
      float: none;
      margin: 0;
      padding: 5px 10px 0;
    }

    #{$self}-thumb-el {
      border: 1px solid rgba(0, 0, 0, 0.9);

      &#{$self}-thumb-active {
        border-color: rgba(0, 0, 0, 0.9);
      }
    }

    #{$self}-thumb-el:hover {
      border: 1px solid rgba(0, 0, 0, 0.9);
    }
  }
}

// .colpick_dark .colpick_color {
//     outline: 1px solid rgba(0, 0, 0, 0.2);
//   }
