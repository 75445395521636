// ================================================
// 			PROJECT VARIABLES
// ================================================
// COLORS

$white		: #FFF;
$black		: #3F4254;
$selection	: rgb(117, 117, 108);

$orange		: #f35625;
$blue-dark	:#1f1451;
$gray-light	:#f4f7fc;


// BACKGROUND-COLORS

.bg-gray{
	background-color: #fafafa;
}

/*.bg-white {
	$bg-white	: #fff;
}*/


// ================================================
//							 AUXILIAR VARIABLES
// ================================================

// SOCIAL MEDIA COLORS
$tw			: #55ACEE;  // TWITTER
$fb			: #3B5998;  // FACEBOOK
$gp			: #DD4B39;  // GOOGLE+
$yt			: #FF3333;  // YOUTUBE
$pin		: #CB2027;  // PINTEREST
$ig			: #517fa4;  // INSTAGRAM
$li 		: #007bb6;  // LINKEDIN