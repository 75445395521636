//
// Content
//

.footer-nav {
  padding: 0 1.5rem;

  &__navigation {
    color: $black;
    border-top: 1px solid $gray-300;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 2rem 0 0;
    display: flex;
    flex-direction: column;
  }

  &__list-item {
    display: inline-block;
    font-size: 1rem;
    color: inherit;
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid $gray-300;

    &--bold {
      font-weight: $font-weight-bolder;
    }
  }

  &__link {
    color: inherit;
    font-size: inherit;
    line-height: inherit;

    &:hover {
      color: $primary;
    }
  }

  &__dropdown {
    display: inline-block;

    &-button {
      gap: 0.75rem;
      padding: 0 2em 0 0 !important; // TODO: remove !important after fixing/standardizing the dropdown styles
      vertical-align: middle;

      &:hover {
        color: $primary;
      }

      &-title {
        font-weight: 400;
      }

      &-icon {
        font-size: get($icon-sizes, md);
      }
    }

    &-menu {
      margin-bottom: 1rem !important; // TODO: remove !important after fixing/standardizing the dropdown styles
    }

    &-item {
      display: flex;
      align-self: center;
      gap: 0.75rem;
      justify-content: space-between;

      &-icon {
        color: $primary;
        font-size: get($icon-sizes, lg);
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .footer-nav {
    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }

    &__list-item {
      border-bottom: none;
      padding: 0 1.5rem;

      &:not(:last-of-type) {
        border-right: 1px solid $gray-500;
      }
    }
  }
}
