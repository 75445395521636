﻿.import-media {
    width: 66.66%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__grid {
        overflow: auto;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-auto-rows: max-content;
        gap: 1rem;
        flex: 1;
        margin-bottom: auto;

        &-item {
            aspect-ratio: 16/9;
            border-radius: 0.28rem;
            overflow: hidden;
            box-sizing: border-box;
            background-color: $gray-100;
            display: flex;
            align-items: center;
            justify-content: center;

            &-image {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            &-icon {
                font-size: 2.25rem;
            }

            &--active {
                border: 2px solid $primary;
            }
        }
    }

    &__progress {
        flex: 1;

        &-title {
            font-weight: 600;
        }
    }

    &__upload {
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;
        height: 40px;
        position: relative;

        &-new {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            &-icon {
                color: $gray-500;
                line-height: 1;
                font-size: 4rem;
            }

            &-subtitle {
                max-width: 480px;
            }
        }

        &-more {
            border: none;
            padding: 0;
            display: flex;
            justify-content: center;
            height: 40px;
            position: relative;

            &:hover > .import-media__upload-more-button {
                background-color: theme-light-color(primary);
            }

            &-input {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                left: 0;
                opacity: 0;
                z-index: 1;
                cursor: pointer;
            }

            &-button {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
                flex: 1;
            }

            &-icon {
                font-size: 1.5rem;
            }
        }
    }
}
