//
// BalloonContentItem
//

.balloon-content-item {
    border-radius: $border-radius;
    color: $black;
    padding: 4px; // TODO (1);

    &__inner {
        align-items: center;
        border-radius: $border-radius;
        display: flex;
        justify-content: space-between;
        padding: 8px 12px; // TODO (1);

        &:where(a:hover *) {
            background-color: $gray-100;
        }
    }

    &__main {
        flex-grow: 1;

        &:where(.balloon-content-item__left ~ *) {
            margin-left: 16px; // TODO (1);
        }
    }

    &__right {
        margin-left: 26px; // TODO (1);
    }

    @include icon-reset;
}
