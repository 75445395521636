//
// BalloonButton
//

/*! purgecss start ignore */

.floater__trigger {
    &--active {
        .balloon-button {
            background-color: $gray-100;
            color: $orange; 
        }
    }

    &:hover {
        .balloon-button {
            background-color: $gray-100;
        }
    }
}

.balloon-button {
    border-radius: $border-radius;
    font-size: $h2-font-size;
    height: 40px; // TODO: replace for variable buttonHeight when it exists
    line-height: 40px; // TODO: replace for variable buttonHeight when it exists
    text-align: center;
    width: 40px; // TODO: replace for variable buttonHeight when it exists

    & .svg-icon {
        line-height: 40px; // TODO: replace for variable buttonHeight when it exists
    }

    @include icon-reset;
}

/*! purgecss end ignore */
