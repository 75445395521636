//
// Dropdown
//


// Base
.dropdown-menu {
    border: 1px solid $gray-300;
    overflow: hidden;
    &:before,
    &:after {
        display: none !important;
    }

    > li > a,
    > .dropdown-item,
    &.dropdown-icon-select .dropdown-item {
        outline: none !important;
        display: flex;
        flex-grow: 1;
        border-radius: 6px;
        width: calc(100% - 1rem);
        margin-left: 0.5rem;

        &.select-active {
            position: relative;
            &:after {
                content: "";
                display: block;
                position: absolute;
                right: 10px;
                top: 15px;
                background-image: url("../media/icons/check-icon.svg");
                width: 0.8em;
                height: 0.8em;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }

        > i {
            &:before {
                line-height: 0;
                vertical-align: middle;
            }
        }
    }

    // RTL mode fix
    .dropdown-menu:not(.daterangepicker) {
        /*rtl:raw:
        left: auto !important;
        */
    }

    // Animations
    &.dropdown-menu-anim-up {
        animation: animation-dropdown-menu-fade-in .3s ease 1, animation-dropdown-menu-move-up .3s ease-out 1;
    }

    .dropup &,
    .dropdown-menu-anim-down {
        &.dropdown-menu-anim {
            animation: animation-dropdown-menu-fade-in .3s ease 1, animation-dropdown-menu-move-down .3s ease-out 1;
        }
    }

    // Alignment
    left: 0#{'/*rtl:ignore*/'};
    /*rtl:raw:
    right: auto;
    */
}

// Widths
.dropdown-menu {
    // Global max-width For Mobile Mode
    @media (max-width: get($dropdown-menu-widths, xxl)) {
        width: 75vw;
    }

    @each $name, $width in $dropdown-menu-widths {
        &.dropdown-menu-#{$name} {
            @media (min-width: $width + 30px) {
                width: $width;
            }
        }
    }
}

// Inline
.dropdown-inline {
    display: inline-block;
}

.dropdown-secondary{
    .dropdown-menu.inner{
        text-align: center;
    }
}


// Animations
@keyframes animation-dropdown-menu-fade-in {
    from   { opacity: 0; }
    to { opacity: 1; }
}

@keyframes animation-dropdown-menu-move-down {
    from   { margin-top: 10px }
    to { margin-top: 0px }
}

@keyframes animation-dropdown-menu-move-up {
    from   { margin-top: 10px }
    to { margin-top: 0px }
}



// Dropdown Icon Select

.dropdown-icon-select {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    max-height: 300px;
    overflow-y: auto;
    [class^="category-container"] {
        margin-bottom: 0.55rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .cat-title {
        padding: 0 0.5rem;
        margin-bottom: 0.25rem;
        color: $gray-600;
    }
    .dropdown-item {
        margin-left: 0rem !important;
        width: auto !important;
        display: inline-block !important;
        padding: 0.25rem;
        .svg-icon {
            opacity: 0.6;
        }
    }
}


// Dropdown Select
.dropdown-accordion-select {
    .dropdown-menu {
        padding: 0;
        border: 1px solid $gray-300;
        overflow: hidden;
        margin-top: -1px;
        box-shadow: none;
    }
    .panel-title a {
        font-weight: 600;
    }
    .panel-body a {
        //padding: 10px 15px;
        display: block;
        color: $gray-800;
        &:hover {
            background-color: $gray-100;
        }
    }
}
.dropdown-accordion .panel-heading {
    padding: 0;
  }
.dropdown-accordion .panel-heading a {
  display: block;
  //padding: 10px 15px;
  font-weight: 600;
  color: $gray-800;
  &:hover {
    background-color: $gray-100;
  }
}
