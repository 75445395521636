//
// Top Navbar
//

/*! purgecss start ignore */

.top-nav {
    align-items: center;
    background: $white;
    display: flex;
    height: 48px;
    justify-content: space-between;
    padding: 4px 30px 4px 12px; //TODO: (1)
  
    &__left {
      align-items: center;
      display: flex;
    }
  
    &__right {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }

    &__menu-toggle {
        display: none;
    }

    &__overlay--open {
        background-color: rgba(0,0,0,.15);
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 99998; // TODO: just to match (.main-navigation -1); change this when the other is changed as well
    }
  
    &__org-dropdown {
      .typeahead {
          // Resets
          &-option {
              background: transparent;
              cursor: initial;
              padding: 0;
  
              &:hover {
                  background: transparent;
              }
  
              &-no-records {
                height: 1px;
                min-height: 400px;
                padding: 0 22px;
              }
          }
          
          &-single-value {
              color: inherit;
              margin-left: unset;
              margin-right: unset;
              max-width: unset;
              position: static;
              text-overflow: ellipsis;
              white-space: nowrap;
              top: 0;
              transform: unset;
              box-sizing: border-box;
          }
          
          &-control {
              border: 0;
              cursor: pointer;
              height: 40px; // TODO: update with variable for dropdowns/input/button heights
              padding: 4px 6px 4px 8px;
              z-index: auto;
  
              &:hover {
                background-color: $gray-100;
              }
          }
          
          &-indicator {
              color: inherit;
              font-size: 1rem;
              margin-left: 6px;
              padding: 0;
  
              &-separator {
                  display: none;
              }
          }

          &-svg {
              height: 1em;
              width: 1em;
          }
  
          &-clear-indicator {
              display: none;
          }
  
          // Styles
          &-container {
              width: 100%;
          }
  
          &-menu {
            border: $border-width solid $gray-200;
            border-radius: $border-radius;
            box-shadow: 0 1px 4px 0 rgba(0,0,0,.05);
            margin-top: 12px; // TODO: (1)
            padding: 8px; // TODO: (1)
            width: auto;

            &-list {
            min-width: 300px;
            }
          }
  
          &-search-input {
              @include svg-bg($search-icon);
              background-repeat: no-repeat;
              background-position: center right 12px;
              background-size: 16px;
              border: $border-width solid $gray-200;
              border-radius: $border-radius;
              font-size: $font-size-sm;
              height: 40px; //TODO: replace by input height variable
  
              &::placeholder {
                  color: $gray-600;
              }
  
              &:focus {
                border-color: $orange;
              }
          }
          
          &-menu-list {
              border: $border-width solid $gray-200;
              border-radius: $border-radius;
              margin-top: 12px; // TODO: (1)
              max-height: 500px;
              padding: 10px 10px 0; // TODO: (1)
          }
  
          &-label {
              color: $gray-600;
              display: block;
              line-height: 2;
          }
  
          &-value {
              align-items: center;
              display: flex;
              font-size: $font-size-lg;
              font-weight: $font-weight-bold;
              justify-content: space-between;

              &-container {
                padding: 0;
              }
  
              &__right {
                  background-color: $white;
                  border-radius: 50%;
                  height: 32px;
                  margin-left: 12px; // TODO: (1)
                  overflow: hidden;
                  width: 32px;
              }
  
              &__img {
                  object-fit: cover;
                  width: 100%;
              }
          }
  
          &-item {
              align-items: center;
              border-bottom: $border-width solid $gray-200;
              cursor: pointer;
              display: flex;
              padding: 8px 10px; // TODO: (1)
  
              &-hover {
                  background-color: $gray-100;
              }
  
              &__left {
                  border-radius: 50%;
                  height: 32px;
                  overflow: hidden;
                  width: 32px;
              }
  
              &__img {
                  object-fit: cover;
                  width: 100%;
              }
  
              &__right {
                  padding-left: 11px; // TODO: (1)
              }
  
              &__title {
                  font-size: $font-size-lg;
                  font-weight: $font-weight-bold;
                  line-height: 1.7;
                  white-space: nowrap;
              }
  
              &__subtitle {
                  color: $gray-600;
                  font-size: $font-size-xs;
                  line-height: 1.2;
              }
          }
      }
  
      @include icon-reset;
    }
  
    &__breadcrumbs {
      margin-left: 16px; // TODO: (1)
    }
  
    &__help-center,
    &__user-info {
      margin-left: 8px; // TODO: (1)
    }
  
  }

  @include media-breakpoint-down(sm) {
    .top-nav {
        &__right {
            display: none;
        }

        &__menu-toggle {
            display: block;
        }
    }
  }

  /*! purgecss end ignore */
