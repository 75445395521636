//
// Email Builder List Page
//

.email-builders {
  [data-toggle="collapse"] {
    .email-builders__arrow-down {
      display: inline-block;
      font-size: 1.5rem;
      transition: transform ease-in-out 0.3s;
    }

    &[aria-expanded="true"] {
      .email-builders__arrow-down {
        transform: rotate(180deg);
      }
    }
  }
}
