// Typeahead Base

.typeahead-container {
    position: relative;

    &:has(.typeahead-background) {
        // Only way to check if it's active at the moment
        .typeahead-control {
            z-index: $z-typeahead-control-active;
        }
    }
}

.typeahead-background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    position: fixed;
    background-color: transparent;
    z-index: $z-typeahead-backdrop;
    cursor: pointer;
}

.typeahead-control {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    cursor: default;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    position: relative;
    box-sizing: border-box;
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    transition: all 100ms ease 0s;
    outline: 0px !important;
}

.typeahead-value-container {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    flex: 1 1 0%;
    padding: 2px 8px;
    overflow: hidden;
}

.typeahead-value-placeholder {
    opacity: .5;
}

.typeahead-single-value {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.typeahead-multi-value {
    background-color: $light;
    display: flex;
    min-width: 0px;
    box-sizing: border-box;
    margin: 2px;
    align-items: center;
    padding: 3px 6px;
    gap: 3px;
    border: 1px solid $gray-600;
    border-radius: 4px;
}

.typeahead-multi-value-label {
    color: rgb(51, 51, 51);
    font-size: 85%;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    border-radius: 2px;
    overflow: hidden;
}

.typeahead-multi-value-clear {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: transparent;
    border: 0;
    outline: none;
}

.typeahead-multi-value-clear:hover {
    background-color: rgb(212, 212, 212);
    color: rgb(222, 53, 11);
}

.typeahead-indicators {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

.typeahead-indicator-separator {
    align-self: stretch;
    background-color: rgb(204, 204, 204);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
}

.typeahead-indicator {
    color: rgb(204, 204, 204);
    display: flex;
    box-sizing: border-box;
    padding: 8px;
    transition: color 150ms ease 0s;
    background-color: transparent;
    border: 0;
    outline: none;
}

.typeahead-clear-indicator {
    color: rgb(204, 204, 204);
    display: flex;
    box-sizing: border-box;
    padding: 8px;
    transition: color 150ms ease 0s;
}

.typeahead-loading-indicator {
    color: rgb(204, 204, 204);
    display: flex;
    align-self: center;
    font-size: 4px;
    line-height: 1;
    margin-right: 4px;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 8px;
    transition: color 150ms ease 0s;
}

.typeahead-loading-dot-1,
.typeahead-loading-dot-2,
.typeahead-loading-dot-3 {
    background-color: rgb(204, 204, 204);
    display: inline-block;
    height: 1em;
    vertical-align: top;
    width: 1em;
    border-radius: 1em;
}

.typeahead-loading-dot-2,
.typeahead-loading-dot-3 {
    margin-left: 1em;
}

.typeahead-loading-dot-1 {
    animation: 1s ease-in-out 0ms infinite normal none running dot-loading;
}

.typeahead-loading-dot-2 {
    animation: 1s ease-in-out 160ms infinite normal none running dot-loading;
}

.typeahead-loading-dot-3 {
    animation: 1s ease-in-out 320ms infinite normal none running dot-loading;
}

.typeahead-svg {
    display: inline-block;
    fill: currentcolor;
    line-height: 1;
    stroke: currentcolor;
    stroke-width: 0;
}

.typeahead-clear-indicator:hover {
    color: rgb(222, 53, 11);
}

.typeahead-chevron-indicator:hover {
    color: rgb(130, 130, 130);
}

.typeahead-menu {
    top: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px;
    margin-bottom: 8px;
    margin-top: 2px;
    position: absolute;
    width: 100%;
    z-index: $z-typeahead-menu;
    box-sizing: border-box;
    border: 1px solid rgb(191, 191, 191);
    border-radius: 4px;
}

.typeahead-search-container {
    margin: 0;
}

.typeahead-search-input {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #bfbfbf;
    padding: .5rem;
    box-sizing: border-box;
}

.typeahead-menu-list {
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 4px;
    padding-top: 4px;
    position: relative;
    box-sizing: border-box;
}

.typeahead-option {
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    display: block;
    font-size: inherit;
    width: 100%;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 8px 12px;
}

.typeahead-option-no-records,
.typeahead-option-loading {
    padding: .5rem;
    opacity: .5;
}

.typeahead-option-footer {
    padding: .5rem;
}

@keyframes dot-loading {
    0%, 80%, 100% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }
}

// Typeahead Customizations

/*! purgecss start ignore */
.typeahead {
    &-option {
        background-color: transparent;
        padding: 5px 5px;

        &:hover {
            background-color: $gray-300;
        }

        &-selected {
            background-color: $gray-400;

            &:hover {
                background-color: $gray-200;
            }
        }
    }
}

.typeahead-value-container {
    max-width: 100%;
}

.typeahead-multi-value-label {
    border-radius: 0px;
}

.typeahead-multi-value-clear {
    height: 14px;
    padding: 0;
    width: 14px;
    border-radius: 4px;

    &:hover {
        background-color: $gray-300;
    }
}

.typeahead-checkbox .typeahead-option {
    padding: 5px 12px 9px;

    &:hover {
        background-color: $gray-100;
        color: inherit;
    }

    &:before {
        content: "";
        display: inline-block;
        height: 18px;
        width: 18px;
        margin-right: get($checkbox-config, labelSpace);
        position: relative;
        background-color: $gray-200;
        top: 4px;
        border-radius: 4px;
    }
}

.typeahead-checkbox .typeahead-option.typeahead-option-selected {
    background-color: transparent;
    color: inherit;

    &:hover {
        background-color: $gray-100;
        color: inherit;
    }

    &:before {
        background-color: $primary;
        background-image: url('../media/icons/iconcheck-white.png');
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: center center;
    }
}

.typeahead-control,
.typeahead-search-input {
    min-height: calc(1.35em + 1.1rem + 2px);
    height: auto;
    line-height: 1.35;
    width: 100%;
    font-weight: 400;
    color: #3F4254;
    background-color: #ffffff;
    background-clip: padding-box;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .typeahead-value-container {
        padding-left: 0.75rem;
    }
}

.typeahead-control {
    border: 1px solid $gray;
}

.typeahead-search-input {
    padding: 0.55rem 0.75rem;
    border: none;
    border-bottom: 1px solid $gray;

    &:focus, &:focus-visible {
        outline: none;
        border-color: $gray;
    }
}

.typeahead-menu {
    border-color: $gray;
    box-shadow: none;
    margin-top: 5px;
}

.typeahead-menu-list {
    padding-top: 0;
}
/*! purgecss end ignore */
