/*! purgecss start ignore */
.preference-center {
    $self: &;
    --border-radius: 6px;
    --box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 100%;
    min-height: 100%;
    background-color: var(--bg-color, #f4f7fc);
    background-image: var(--bg-image, none);
    overflow: auto;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    font-size: 1rem;
    font-family: var(--font-family, "Poppins", sans-serif);
    color: var(--text-color, #3f4254);

    & :where(a) {
        color: var(--primary-color);
        text-decoration: underline;
        cursor: pointer;
        transition: 120ms all ease-in-out;

        &:hover,
        &:focus {
            color: var(--text-color, #3f4254);
        }
    }

    &__container {
        position: absolute;
        width: 100%;
        min-height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 120px;
        width: 100%;
        padding: 20px;
        gap: 20px;
        background-color: var(--header-bg-color, transparent);
        flex-wrap: wrap;
        border-radius: var(--border-radius, 6px);

        &-logo {
            max-width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &-title {
            font-size: 2rem;
            font-weight: 700;
            color: var(--header-title-color, inherit);
            white-space: nowrap;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex: 1;
    }

    &__card {
        --spacing: 40px;
        width: 100%;
        height: 100%;
        max-width: 1080px;
        background-color: var(--card-bg-color, #fff);
        border: 1px solid var(--card-outline-color, #B5B4C3 );
        border-radius: var(--border-radius, 6px);
        box-shadow: var(--box-shadow);

        &--inner {
            --spacing: 20px;
            box-shadow: none;
        }

        &-header,
        &-body,
        &-footer {
            display: flex;
            flex-direction: column;
            gap: var(--spacing);
            padding: var(--spacing);
        }

        &-body {
            padding-top: 0;
        }

        &-footer {
            border-top: 1px solid var(--card-outline-color, #B5B4C3);
            flex-direction: row;
            justify-content: end;
        }

        &-title {
            font-size: 1.66rem;
            font-weight: 600;
            color: var(--card-title-color, inherit);
            align-items: end;
            display: flex;
            justify-content: space-between;

            &--sm {
                font-size: 1.33rem;
            }

            &--xs {
                font-size: 1.25rem;
            }
        }

        &-title-cell {
            display: flex;
            flex-direction: row;
        }

        &-title-checkbox {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-subtitle {
            font-size: 1.15rem;
            font-weight: 400;
            color: var(--card-subtitle-color, inherit);

            &--sm {
                font-size: 1rem;
            }
        }
    }

    &__profile-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--spacing);
        width: 100%;
    }

    &__input-field {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;

        &-label {
            font-weight: 600;
            color: var(--input-label-color, inherit);
        }

        &-text {
            padding-left: 1rem;
        }
    }

    &__table-wrapper {
        width: 100%;
        padding: 0 42px;

        &--sm {
            padding: 0 21px;
        }

        &--xs {
            padding: 0;
        }
    }

    &__table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 1rem;
        color: var(--text-color, #3f4254);
        border-radius: var(--border-radius, 6px);

        &-row {
            &:nth-child(even) {
                background-color: var(--table-row-bg-color, #f4f7fc);
            }
        }

        &-cell {
            padding: 0;
            text-align: center;
            vertical-align: middle;
            line-height: 1;
            width: 50%;

            &--header {
                padding: 1rem 1.5rem;
                color: $gray-600;
                font-weight: 600;
            }

            &--row-header {
                padding: 1rem 1.5rem;
                text-align: left;
                max-width: unset;
                width: 100%;
            }
        }
    }

    &__sendout-channel {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
    }

    &__sendout-icon {
        font-size: 1.75rem;
    }
    &__channel-label {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0;
        cursor: pointer;
        transition: all 120ms ease-in-out;
        justify-content: center;
        padding: 1rem 1.5rem;

        &-title {
            display: none;
        }
    }

    &__checkbox {
        appearance: none;
        background: $gray-200;
        border: $border-width solid $gray-200;
        border-radius: 0.25em;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 16px;
        height: 1em;
        line-height: 1em;
        position: relative;
        transition: 120ms all ease-in-out;
        width: 1em;

        &:after {
            border: 0.1em solid var(--primary-inverse-color);
            border-left-width: 0;
            border-top-width: 0;
            content: "";
            height: 0.5em;
            left: 50%;
            position: absolute;
            rotate: 45deg;
            top: 45%;
            transform: scale(0);
            transition: 120ms all ease-in-out;
            translate: -50% -50%;
            visibility: visible;
            width: 0.25em;
        }

        &:hover,
        &:focus {
            border-color: $gray-500;
            cursor: pointer;
        }

        &:checked {
            background: var(--primary-color);
            border-color: transparent;

            &:after {
                transform: scale(1);
            }

            &:hover,
            &:focus {
                opacity: 0.5;
                background: var(--primary-color);
            }
        }

        &:disabled {
            cursor: not-allowed;
            border-color: transparent;
            opacity: 0.5;
            background: $gray-200;

            &:checked {
                background: var(--primary-color);
            }
        }
    }

    &__unsubscribe-all {
        display: flex;
        justify-content: center;
        color: var(--primary-color);

        &-link {
            text-decoration: none;
        }
    }

    &__button {
        display: inline-block;
        background-color: var(--primary-color);
        color: var(--primary-inverse-color);
        transition: 120ms all ease-in-out;
        font-size: 1.15rem;
        font-weight: 600;
        outline: none;
        vertical-align: middle;
        text-align: center;
        border: 1px solid transparent;
        border-radius: var(--border-radius, 6px);
        user-select: none;
        line-height: 1.5;
        padding: 0.65rem 2rem;

        &:hover,
        &:focus {
            background-color: var(--primary-inverse-color);
            color: var(--primary-color);
        }
    }

    @include media-breakpoint-down(md) {
        #{$self}__table {
            &-row {
                display: flex;
                flex-wrap: wrap;
                gap: 0.5rem;
                justify-content: space-between;
                background-color: transparent;
                border-radius: var(--border-radius, 6px);
                border: 1px solid var(--card-outline-color, #B5B4C3);
                padding: var(--spacing);
                &:first-child {
                    display: none;
                }
            }
            &-cell {
                text-align: left;
                padding: 0;
                width: auto;
                flex: 1;
                border-radius: var(--border-radius, 6px);
                background-color: var(--table-row-bg-color, #f4f7fc);
                border: 1px solid transparent;

                &--row-header {
                    flex: auto;
                    width: 100%;
                    padding: 0.75rem 1rem;
                    font-weight: 600;
                    font-size: 1.15rem;
                    border-color: transparent;
                    background-color: transparent;
                }
            }
        }
        #{$self}__channel-label {
            justify-content: space-between;
            gap: 1rem;
            border: 1px solid transparent;
            border-radius: var(--border-radius, 6px);
            padding: 0.75rem 1rem;

            &:hover,
            &:focus-visible {
                color: var(--primary-color);
                border-color: var(--primary-color);
            }
            &-title {
                display: flex;
                gap: 0.5rem;
                align-items: center;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        #{$self}__card {
            --spacing: 20px;
            &--inner {
                --spacing: 15px;
            }
            #{$self}__table {
                &-row:not(&-row:first-child) {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    gap: 0;
                    border: 1px solid var(--card-outline-color, #B5B4C3);
                }
                &-cell {
                    width: 100%;
                    background-color: transparent;
                    &:nth-child(even) {
                        background-color: var(--table-row-bg-color, #f4f7fc);
                    }
                }
            }
        }
    }
}
/*! purgecss end ignore */
