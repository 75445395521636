.release-bar {
  align-items: center;
  background: rgba(157, 188, 255, 0.5);
  column-gap: 0.75rem;
  display: flex;
  min-height: 48px;
  padding: 0 0.75rem;
  
  &__content {
    column-gap: 0.25rem;
    display: flex;
    flex-wrap: wrap;
  }
  
  &__link-wrapper {
    column-gap: 0.25rem;
    display: flex;
    flex-wrap: wrap;
  }
  
  &__link {
    color: $dark;
    font-weight: $font-weight-bold;
    text-decoration: underline;
    white-space: nowrap;
  }
}