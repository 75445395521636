﻿.media-details {
    &__content {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        overflow: auto;
        width: 100%;

        &-description {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            font-size: 0.925rem;

            &-name {
                font-weight: 600;
            }

            &-links {
                display: inline-flex;
                gap: 0.5rem;
                padding: 0 0.5rem;

                &-item {
                    color: $primary;

                    &:after {
                        content: ";";
                    }
                }
            }
        }
    }

    &__preview {
        max-height: 40rem;
        border-radius: 0.5rem;
        background-color: $gray-100;
        width: 100%;
        display: flex;
        overflow: hidden;

        &-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            background-image: url('/media/pages/contentLibrary/transparent-background.jpg');
            background-size: cover;
        }

        &-video {
            margin: auto;
            max-height: 100%;
            max-width: 100%;
        }

        &-icon {
            font-size: 3rem;
            width: 100%;
            aspect-ratio: 16/9;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-thumbnail {
            border-radius: 0.28rem;
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
        }
    }

    &__edit {
        display: flex;
        flex-direction: column;
        width: 400px;
        padding: 1rem;
        border-radius: 0.5rem;
        background: $gray-100;
        gap: 1rem;
        font-size: 0.925rem;

        &-panel {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
        }

        &-field {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            &-title {
                font-weight: 600;
            }

            &-info {
                font-size: 10px;
            }
        }

        &-actions {
            display: flex;
            gap: 1rem;
        }

        &-label {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            cursor: pointer;
        }

        &-checkbox {
            background: $white;
        }

        &-thumbnail-field {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        &-tag {
            $tag: &;

            &-options {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:hover > #{$tag}-delete {
                    visibility: visible;
                }
            }

            &-delete {
                visibility: hidden;
                color: $danger;
            }

            &-add {
                color: $primary;
                justify-content: center;
                font-size: 1rem;
            }

            &-icon {
                font-size: 1.5rem;
                line-height: 1;
            }
        }
    }

    &__public-url {
        display: flex;
        gap: 1rem;

        &-link {
            white-space: nowrap;
            overflow: hidden;
            background-color: $gray-200;
            border-radius: 0.28rem;
            padding: 0.8rem 1rem;
        }

        &-clipboard {
            cursor: pointer;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        flex: 1;
        min-width: 100px;

        &-icon {
            line-height: 1;
            font-size: 1.5rem;
        }
    }
}
