//
// checkbox
//


// Base
.checkbox {
    display: flex;
    align-items: center;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    transition: get($checkbox-config, transition);
    margin: 0;

    > span {
        background-color: none;
		display: flex;
        align-items: center;
        justify-content: center;
        @include border-radius($border-radius);

        &:after {
            content:'';
			border-color: transparent;
			border-style: solid;
            border-width: 0 2px 2px 0#{'/*rtl:ignore*/'} !important;
            transform: rotate(45deg)#{'/*rtl:ignore*/'};
			margin-top: -2px;
        }
    }

    // Hide default browser input
    > input {
        position: absolute;
        z-index: 1;
        opacity: 0;
    }

    // Hover & focus states
	&:hover > input:not([disabled]) ~ span,
	> input:focus ~ span {
		transition: get($checkbox-config, transition);
	}

    // Disabled state
    &.checkbox-disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    // Checked state
    > input:checked ~ span {
        transition: get($checkbox-config, transition);
        background-color: none;

        &:after {
            display: block;
        }
    }

    // Invisible input takes all size of container in order to be clickable on the label
    &.checkbox-fill {
        input {
            opacity: 0;
            width: 100%;
            height: 100%;
        }
    }

	// Square Style
    &.checkbox-square {
        > span {
            border-radius: 6px;
            margin-right: 0.5rem;
        }
    }

	// Circle Style
    &.checkbox-circle {
        position: relative;
        > span {
            transition: get($checkbox-config, transition);
			@include border-radius(50%);
            &:after {
                display: none !important;
            }
        }

        input {
            width: 100%;
            height: 100%;
            z-index: 1;
            transition: get($checkbox-config, transition);
            &:checked ~ span {
                color: white !important;
                border: 1px solid $primary !important;
            }
            & ~ span {
                background: white;
                color:$gray-500;
                border: 1px solid $gray;
            }
        }
    }

	// Outline type
    &.checkbox-outline {
        > span {
            border-width: 1px;
			border-style: solid;
        }
    }

    // Outline 2x type
	&.checkbox-outline-2x {
		> span {
            border-width: 2px !important;
        }
	}

    // Accent type
    &.checkbox-accent {
        > span {
            border-width: get($checkbox-config, types, accent, borderWidth) !important;
            border-style: solid !important;
            background-color: transparent !important;

            &:after {
    			display: block;
            }
        }
    }
}

// Sizes
.checkbox {
    // Solid type
    @include checkbox-size(get($checkbox-config, types, solid, sizes, default, base), get($checkbox-config, types, solid, sizes, default, tick));
    &.checkbox-lg {
        @include checkbox-size(get($checkbox-config, types, solid, sizes, lg, base), get($checkbox-config, types, solid, sizes, lg, tick));
    }

    // Outline type
    &.checkbox-outline {
        @include checkbox-size(get($checkbox-config, types, outline, sizes, default, base), get($checkbox-config, types, outline, sizes, default, tick));

        &.checkbox-lg {
            @include checkbox-size(get($checkbox-config, types, outline, sizes, lg, base), get($checkbox-config, types, outline, sizes, lg, tick));
        }
    }
}

// Theme colors
.checkbox {
    // Default style
	@include checkbox-solid-theme(get($checkbox-config, types, solid, theme, base-color), $primary, $white);

    // Outline style
    &.checkbox-outline {
        @include checkbox-outline-theme(get($checkbox-config, types, outline, theme, base-color), $primary, $primary);
    }

    // Color options
    @each $name, $color in $theme-colors {
		// Default style
        &.checkbox-#{$name} {
            @include checkbox-solid-theme(get($checkbox-config, color), $color, $white);
        }

        // Light style
        &.checkbox-light-#{$name} {
            @include checkbox-solid-theme(theme-light-color($name), $color, theme-inverse-color($name));
        }

		// Outline style
		&.checkbox-outline.checkbox-#{$name} {
            @include checkbox-outline-theme(get($checkbox-config, types, outline, theme, base-color), $color, $color);
        }
    }
}

// Inline checkbox
.checkbox-inline {
    display: flex;

    .checkbox {
        margin-right: get($checkbox-config, itemSpace);

        span {
            margin-right: get($checkbox-config, labelSpace);
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

// List checkbox
.checkbox-list {
    display: flex;
    flex-direction: column;

    .checkbox {
        margin-bottom: get($checkbox-config, itemSpace);

        span {
            margin-right: get($checkbox-config, labelSpace);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}


// CheckBox List Buttons

.checkbox-button-hover {
    @for $i from 1 through 10 {
        &:nth-of-type(#{$i}) {
            &:hover {
                & ~ [class^="hover-tip"] {
                    & > div {
                        &:nth-of-type(#{$i}) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
.hover-tip {
    top: 10px;
    left: 3px;
    height: 26px;
    float: left;
    position: relative;
    width: 100%;
    & > div {
        display: none;
        position: absolute;
        top: 1px;
        left: -1px;
        width: 100%;
    }
}


.checkbox-list-buttons {
    display: flex;
    flex-direction: column;
    &.w-border {
        .checkbox-button {
            & > div {
                border: 1px solid $gray;
            }
        }
    }
}
.checkbox-list-buttons,
.checkbox-list-dropdown {

    .checkbox-button {
        position: relative;
        margin-bottom: get($checkbox-config, itemSpace);

        &.someselected {
            span {
                &:before {
                    position: absolute;
                    top: 50%;
                    height: 2px;
                    margin-top: -1px;
                    background-color: $gray-500;
                    content: "";
                    width: 10px;
                }

                svg {
                    display: none;
                }
            }
        }

        span {
            margin-right: get($checkbox-config, labelSpace);
            position: relative;

            svg {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 1;

                g {
                    [fill] {
                        fill: white;
                    }
                }
            }
        }

        .fake-checkbox-button {
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
            height: 100%;
            opacity: 0;
            z-index: 1;
            display: none;

            &:disabled {
                & + div {
                    border-color: $primary;

                    span {
                        border-color: $primary;

                        svg {
                            g {
                                [fill] {
                                    fill: $primary;
                                }
                            }
                        }
                    }
                }
            }
        }

        input {
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
            height: 100%;
            opacity: 0;
            z-index: 1;

            &:hover:not(:checked) {
                & + div {
                    border-color: $gray-500;
                    background-color: $gray-100;

                    span {
                    }
                }
            }

            &:checked {
                & + div {
                    border-color: $primary;

                    span {
                        border-color: $primary;
                        background-color: $primary;

                        svg {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        div {
            background: white;
            width: 100%;
            min-height: 40px;
            display: block;
            border: 1px solid white;
            border-radius: 4px;
            position: relative;
            padding: 10px 10px 10px 35px;

            img {
                max-width: 100%;
                max-height: 30px;
                vertical-align: middle;
                line-height: 40px;
                height: 30px;
                object-fit: contain;
            }

            span {
                border: 1px solid $primary;
                border-radius: 4px;
                position: absolute;
                height: 16px;
                width: 16px;
                top: 49%;
                left: 10px;
                background: white;
                transform: translateY(-50%);
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.no-check-symbol {
        &.someselected {
            span.svg-icon {
                &:before {
                    position: absolute;
                    top: 50%;
                    height: 2px;
                    margin-top: -1px;
                    background-color: $gray-500;
                    content: "";
                }

                svg {
                    display: none;
                }
            }
        }

        .dropdown-menu & {
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        span.svg-icon {
            position: relative;
            display: block;

            svg {
                position: relative;
                width: auto;
                height: auto;

                g {
                    [fill] {
                        fill: $gray-500;
                    }
                }
            }
        }

        input {
            &:hover:not(:checked) {
                & + div {
                    span {
                        border: none;
                        background-color: transparent;

                        svg {
                            g {
                                [fill] {
                                    fill: $gray-500;
                                }
                            }
                        }
                    }
                }
            }

            &:checked {
                & + div {
                    span {
                        border: none;
                        background-color: transparent;

                        svg {

                            g {
                                [fill] {
                                    fill: $primary;
                                }
                            }
                        }
                    }
                }
            }
        }

        div {
            padding-left: 10px;

            span {
                border: none;
                border-radius: 0;
                position: relative;
                top: 0;
                left: 0;
                transform: translateY(0%);
                height: auto;
                width: auto;
                margin-right: 0;
            }
        }
    }
}

// Input group
.input-group {
    .checkbox {
        margin-bottom: 0 !important;
        padding-left: 0;
    }
}

// Form integration
.form-inline {
    .checkbox {
        margin-left: 15px;
        margin-right: 15px;
    }
}
