/*! purgecss end ignore */
//
// Display Grid Utility Classes
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @for $i from 1 through $grid-columns {
      .grid#{$infix}-#{$i}-col {
        grid-template-columns: repeat(#{$i}, 1fr);
      }
    }

    @each $size, $length in $spacers {
      .gap#{$infix}-#{$size} {
        grid-gap: $length !important;
      }

      .gap-y#{$infix}-#{$size} {
        grid-row-gap: $length !important;
      }

      .gap-x#{$infix}-#{$size} {
        grid-column-gap: $length !important;
      }
    }
  }
}

.d-grid {
  display: grid;
}
/*! purgecss end ignore */
