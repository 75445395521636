/*! purgecss start ignore */
.profiles-card-item {
  --_button-size: 24px;
  --_padding-y: 0.25rem;
  --_padding-x: 0;
  --_number-font-size: var(--number-font-size, 1.8rem);
  --_border-weight: var(--border-weight, 0);

  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: var(--_padding-y) var(--_padding-x);
  border-bottom: var(--_border-weight) solid $border-color;
  font-size: inherit;
  min-height: calc(var(--_button-size) + var(--_padding-y) * 2);
  width: 100%;
  color: inherit;

  &:last-child {
    border-bottom: none;
  }

  &__icon {
    color: $primary;
  }

  &__main {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
    overflow: hidden;
  }

  &__number {
    font-weight: $font-weight-bolder;
    font-size: var(--_number-font-size);
    vertical-align: bottom;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: inherit;
    vertical-align: bottom;
  }

  &__loading {
    display: flex;
  }

  &__tooltip {
    &-icon {
      color: $gray-500;
    }
    &-content {
      margin: 0;
    }
  }

  &__left {
    margin-left: auto;
    min-width: var(--_button-size);
    min-height: var(--_button-size);
  }
}
/*! purgecss end ignore */
