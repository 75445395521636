﻿//
// Content Repository's Modals
//

.repository__modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    outline: 0;
    overflow: auto;
    display: block;

    &--overlay {
        background: #000000b3;
    }
}

.repository__modal-dialog {
    align-items: center;
    position: relative;
    width: 1100px;
    display: flex;
    min-height: calc(100% - 42px);
    pointer-events: none;
    transform: none;
    margin: 1.75rem auto;
    max-width: 100%;
}

.repository__modal-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 42px);
    pointer-events: auto;
    background-color: white;
    border-radius: 0.5rem;
}

.repository__modal-header {
    display: flex;
    padding: 1rem;
    gap: 0.5rem;
}

.repository__modal-title {
    margin: 0;
    font-weight: 600;
}

.repository__modal-close {
    margin-left: auto;
}

.repository__modal-hr {
    margin: 0;
}

.repository__modal-header-title {
    border-color: currentColor;
    width: 25%;
    border-width: 3px;
    overflow: hidden;
    border-bottom: 1px solid #eaedf2;
}

.repository__modal-body {
    display: flex;
    gap: 1rem;
    padding: 1rem;

    &:is(.move-files-modal *) {
        display: block;
    }
}

.repository__modal-footer {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}
.repository__modal-button {
    min-width: 100px;
}
