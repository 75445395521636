.emoji-panel {
  padding: 7px;
  width: 408px;
  height: 403px;
  cursor: pointer;
  overflow: scroll;

  input {
    margin: 3px 3px 5px;
    width: calc(100% - 6px);
  }

  .dropdown-item {
    font-size: 20px;
    letter-spacing: 0.2em;
    display: inline-block !important;
    width: calc(1em + 0.3em * 2);
    padding: 0 0.3em;
    margin: 0;
  }
}