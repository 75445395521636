//
// Expandable
//

/*! purgecss start ignore */
.expandable {
    $self: &;
    border-color: $gray-500;
    border-style: solid;
    border-width: 0;
    width: 100%;

    &__trigger {
        cursor: pointer;
    }

    &__content {
        display: none;
    }

    &--open {
        > #{$self}__content {
            display: block;
        }
    }

    @include icon-reset;
}
/*! purgecss end ignore */
