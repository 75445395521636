//
// Bootstrap Datetimepicker
//


// Base
  /*! purgecss start ignore */

// TODO: Replace with form-control mixin
.datetimepicker-value-container {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: .65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-color: transparent;
    background-clip: padding-box;
    border: none;
    border-radius: 0;
    box-shadow: none;
    z-index: 1;
}

.datetimepicker {
    @include border-radius($border-radius);

    .datetimepicker-value-container {
        width: 100%;
        padding: 0;
        display: flex;
    }

    &.is-datepicker-open,
    &.is-timepicker-open {
        .datetimepicker-time-button,
        .datetimepicker-calendar-button {
            color: $primary;
        }
    }
    
    .datetimepicker-calendar-button,
    .datetimepicker-time-button,
    .datetimepicker-clear-button {
        position: absolute;
        color: $gray-400;
    }

    .datetimepicker-calendar-button,
    .datetimepicker-time-button {
        right: 5px;
    }

    .datetimepicker-clear-button {
        right: 30px;
    }

    .datepicker-cell {
        &.is-today {
            border: none;
            background-color: $gray-100;
        }
        
        &:hover {
            background-color: $primary;
            color: white;
        }
    }
    
    .datepicker-modal,
    .timepicker-modal {
        border: 1px solid $gray-300;
        box-shadow: none;

        .datepicker-select-month, 
        .datepicker-input-year {
            background: white;
        }
    }

    .datetimepicker-input {
        border-radius: .42rem;
        transition: outline-color .15s ease-in-out;
        background-color: white;
        outline: 1px solid $gray-300;

        &:focus {
            outline: 1px solid $primary;
        }
      }

    .datepicker-cell.is-selected, 
    .timepicker-cell.is-selected {
        background-color: $primary;
    }

    .timepicker-cell {
        &:hover {
            background-color: $primary-lightest;
        }
    }

    &.datetimepicker-orient-top {
        margin-top: 8px;
    }

    table {
        width: 100%;
    }

    td,
    th {
        font-size: 1rem;
        width: 33px;
        height: 33px;
        font-weight: regular;
        @include border-radius($border-radius);
    }

    thead {
        th {
            i {
                font-size: 1.2rem;
            }

            &.prev,
            &.switch,
            &.next {
                color: $dark-75;
                font-weight: 500;
                display: table-cell;

                .glyphicon {
                    color: $dark-50;
                }

                &:hover {
                    background: $gray-100 !important;
                }
            }

            &.dow {
                font-weight: 500;
                color: $dark-75;
            }

            &.next {
                > span {
                    &:before {
                        @include ki(get($ki-types, arrowNext));
                        font-size: 0.7rem;
                    }
                }
            }

            &.prev {
                >span {
                    &:before {
                        @include ki(get($ki-types, arrowBack));
                        font-size: 0.7rem;
                    }
                }
            }
        }
    }

    tbody {
        tr>td {
            span {
                color: $dark-50;
                font-weight: 400;
            }

            &.day {
                color: $dark-50;

                &:hover {
                    background: $gray-100;
                }

                &.old {
                    color: $dark-50;
                }

                &.new {
                    color: $dark-75;
                }

                &.selected,
                &.active {
                    background: $primary;
                    color: $white;
                }

                &.today {
                    position: relative;
                    background: rgba($primary, 0.12) !important;
                    color: $primary !important;

                    &:before {
                        content: '';
                        display: inline-block;
                        border: solid transparent;
                        border-width: 0 0 7px 7px;
                        border-bottom-color: #ffffff;
                        border-top-color: $gray-200;
                        position: absolute;
                        bottom: 4px;
                        right: 4px;
                    }
                }

                &.range {
                    background: $gray-100;
                }
            }

            span.year,
            span.hour,
            span.minute,
            span.month {
                color: $dark-50;

                &.focused,
                &:hover {
                    background: $gray-100;
                }

                &.active:hover,
                &.active {
                    background: $primary;
                    color: $white;
                }
            }
        }
    }

    tfoot {
        tr>th {
            &.today,
            &.clear {
                @include border-radius($border-radius);

                font-weight: 500;

                &:hover {
                    background-color: $gray-100;
                }
            }
        }
    }

    &.datetimepicker-inline {
        border: 1px solid $gray-100;
    }
}

.input-daterange {
    .input-group-addon {
        min-width: 44px;
    }
}
  /*! purgecss end ignore */
