.button {
	$self: &;
	border-radius: $button-radius;
	font-size: $button-font-size;
	font-weight: $button-font-weight;
	min-height: $button-height;
	min-width: 140px;
	padding: $button-base-padding;
	cursor: pointer;

	&:disabled {
		opacity: .5;
	}

	//Colors

	// Theme Colors
	@each $name, $value in $theme-colors {
		
		// Default Style
		&--#{$name} {
			background-color: $value;
			border: $border-width solid transparent;
			color: theme-inverse-color($name);

			&:hover,
			&:active,
			&:focus {
				background-color: theme-hover-color($name);
			}
		}

		// Outline Style
		&--#{$name}-outline {
			background-color: theme-inverse-color($name);
			border: $border-width solid currentColor;
			color: $value;

			&:hover,
			&:active,
			&:focus {
				background-color: theme-light-color($name);
			}
		}
	}

	// Social buttons
	@each $name, $value in $social-colors {
		// Default Style
		&--#{$name} {
			background-color: get($value, base);
			color: get($value, inverse);
		}

		// Light Style
		&--#{$name}-light {
			background-color: get($value, light);
			color: get($value, inverse);
		}

		// Outline Style
		&--#{$name}-outline {
			background-color: get($value, inverse);
			border: $border-width solid currentColor;
			color: get($value, base);
		}
	}

	&--icon {
		align-items: center;
		display: flex;
		column-gap: 8px;
		justify-content: center;
		min-width: unset;

		:where(i) {
			display: inline-flex;
			font-size: get($icon-sizes, lg);
			line-height: 1;
		}
	}

	&--full-width {
		justify-content: center;
		text-align: center;
		width: 100%;
	}

	&--borderless {
		border: $border-width solid transparent;
	}

	&--transparent {
		background-color: transparent;
	}

	&--link {
		background-color: transparent;
		border: $border-width solid transparent;
	}

	&--small {
		max-width: 40px;
	}

	&--xs {
		max-width: 30px;
		min-height: 30px;
	}
}
