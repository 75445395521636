$darkPurple: #161420;
$purple: #1f1451;
$navbarBGColor: $purple;
$grayColor: #bdbdbd;

/*! purgecss start ignore */
.main-navigation {
  width: $nav-menu-collapsed;
  height: 100vh;
  background-color: $navbarBGColor;
  position: relative;
  z-index: 99999;

  &__container {
    width: $nav-menu-collapsed;
    height: 100%;
    position: fixed;
    background-color: $navbarBGColor;
    transition: width 0.2s ease;
    display: grid;
    grid-template-rows: repeat(7, min-content);
    overflow-x: hidden;
    overflow-y: hidden;
    @include scrollbar-inside;
  }

  & &__pill {
    /* TODO: reorganize components' style imports, to order 
    /* from more generic to more specific, so we don't 
    /* have the need of increasing specificity like this
    */
    
    display: none;
  }

  &--open &__container,
  &:hover &__container {
    overflow-y: auto;
    overflow-y: overlay;
  }

  &__header {
    padding: 20px 12px 32px;
    width: 100%;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: $navbarBGColor;
    z-index: 2;

    a {
      display: inline-block;
      font-size: 0;
      line-height: 0;
    }

    button {
      position: absolute;
      top: 30px;
      right: 12px;
      background: none;
      border: none;
      display: none;
      color: $grayColor;
    }

    .menu-fold {
      display: none;
    }
  }

  &__logo {
    line-height: 1;
    font-size: 46px;
    color: $primary;
    vertical-align: middle;
  }

  &__nav-group {
    &--self-end {
      align-self: end;
      margin-bottom: 36px;

      .main-navigation__group-title {
        top: 0;
      }
    }
  }

  &__group-title {
    padding: 10px 18px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    background: $navbarBGColor;
    min-height: 33px;

    // &:nth-of-type(2) {
    //   top: 85px;
    // }

    // &:nth-of-type(4) {
    //   top: 115px;
    // }

    // &:nth-of-type(6) {
    //   top: 145px;
    // }

    &::after {
      content: "";
      display: block;
      background-color: $grayColor;
      opacity: 0.4;
      height: 1px;
      top: 6px;
      position: relative;
    }

    span {
      font-size: 10px;
      line-height: 13px;
      font-weight: 600;
      color: $grayColor;
      margin-right: 10px;
      text-transform: uppercase;
      display: none;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__sub-nav {
    margin: 0;
    padding: 0;
    list-style: 0;
    max-width: 4px;
    margin-top: -56px;
    transition: (max-height 0.36s ease-out, margin-top 0.2s ease);
    background-color: #191041;

    li {
      height: 56px;
      transition: height 0.2s ease;

      @for $i from 1 through 20 {
        &:nth-of-type(#{$i}) {
          margin-top: -(($i - 1) * 56px);
        }
      }

      a,
      button {
        // PaddingLeft = button padding + icon width + icon margin
        padding: 12px 22px 12px calc(18px + 26px + 11px);
        transition: (padding-top 0.2s ease-out, padding-bottom 0.2s ease-out);
      }
    }
  }

  &__list-item {
    width: 100%;
    white-space: nowrap;
    border-radius: 0;
    background-color: transparent;
    overflow: hidden;

    &-icon {
      align-items: center;
      display: flex;
      font-size: 24px;
      justify-content: center;
      height: 100%;
      left: 20px;
      position: absolute;
      top: 0;
    }

    &-title {
      flex: 1;
    }

    a,
    button {
      background-color: transparent;
      border: none;
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
      white-space: nowrap;
      text-align: left;
      padding: 12px 18px; // Review 8px top bottom
      border-left: 4px solid transparent;
      color: $grayColor;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      gap: 12px;
      min-height: 48px;
      transition: color 0.2s ease;

      &:hover,
      &.active,
      &[aria-expanded="true"] {
        color: white;

        .main-navigation__collapse-arrow-down {
          [fill] {
            fill: white;
          }
        }

        .badge {
          &:not(.badge--outline) {
            background-color: $white;
            color: #222222;
          }

          &.badge--outline {
            border-color: $white;
            color: white;
          }
        }
      }

      &:hover {
        background-color: #191041;
      }

      &.active {
        border-color: $primary;
      }

      &[aria-expanded="true"].active {
        border-color: transparent;
      }

      .main-navigation__collapse-arrow-down {
        transform: rotate(0deg);
        transition: transform 0.36s ease-out;
        display: none;
        [fill] {
          fill: $grayColor;
        }
      }

      &[aria-expanded="true"] {
        .main-navigation__collapse-arrow-down {
          transform: rotate(180deg);
        }
      }

      span {
        font-size: 14px;
        display: none;

        &.badge {
          border-radius: 50px;
          display: none;
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          padding: 0 8px;
          position: absolute;
          right: 24px;
          text-transform: uppercase;
          top: 50%;
          transform: translateY(-50%);

          &:not(.badge--outline) {
            background-color: $grayColor;
            color: #222222;
          }

          &.badge--outline {
            border: 1px solid $grayColor;
            color: $grayColor;
          }
        }
      }
    }
  }
}

// Altered States
.main-navigation {
  $self: &;

  &--open {
    width: $nav-menu-expanded;

    .menu-unfold {
      display: none;
    }
    .menu-fold {
      display: initial;
    }
  }

  &:hover,
  &--open {
    #{$self}__pill {
      display: block;
    }

    .main-navigation__header {
      button {
        display: inline-block;
      }
    }

    .main-navigation__container {
      width: $nav-menu-expanded;
    }

    .main-navigation__list-item {
      span {
        display: inline-block !important;
      }

      button {
        &[aria-expanded="true"] {
          background-color: #191041;
        }

        .main-navigation__collapse-arrow-down {
          display: inline-flex;
        }
      }
    }

    .main-navigation__group-title {
      padding: 10px 24px 10px 18px;
      grid-template-columns: min-content 1fr;

      span {
        display: block;
      }
    }

    .main-navigation__sub-nav {
      max-width: $nav-menu-expanded;
      margin-top: 0;

      li {
        height: auto;
        margin-top: 0;

        a {
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .main-navigation {
    position: absolute;
    width: 0;

    &__container {
      width: 0;
    }

    &--open {
      width: $nav-menu-expanded;

      & .main-navigation__container {
        width: $nav-menu-expanded;
      }
    }

    & &__header &__toggle-btn {
      display: none;
    }
  }
}

/*! purgecss end ignore */
