.input-label {
    font-size: 12px;
    font-weight: 600;
}

.caption {
    font-size: 8px;
    line-height: 1.2em;
    position: relative;
    &--note {
        &:before {
            content: "*";
            position: absolute;
            left: -1em;
            top: 0;
        }
    }
}