//
// Base
//

// Body
body {
  background: $light;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Wrapper
  .wrapper {
    transition: get($aside-config, base, transition);

    // Secondary Aside Enabled Mode
    .aside-secondary-enabled.aside-fixed & {
    }

    // Secondary Aside Disabled Mode
    .aside-secondary-disabled.aside-fixed & {
    }

    // Fixed Aside & Minimized Modes
    .aside-fixed.aside-minimize & {
      transition: get($aside-config, base, transition);
    }

    // Fixed Subheader Mode
    .subheader-fixed.subheader-enabled & {
      padding-top: get($subheader-config, fixed, height);
    }

    // Footer Fixed Mode
    .footer-fixed & {
      padding-bottom: get($footer-config, fixed, height);
    }
  }

  // Containers
  .container,
  .container-fluid {
    padding: 0 get($page-padding, desktop);
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-mobile-fixed & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height);
    }
  }

  // Containers
  .container,
  .container-fluid {
    max-width: none;
    padding: 0 get($page-padding, tablet-and-mobile);
  }
}

/* Content main Div */

#kt_content {
  min-height: calc(100vh - 90px - 55px);
}

.main-body {
  display: grid;
  grid-template-columns: min-content 1fr;
}

.layout {
  &__main {
    display: flex;
    flex-direction: column;
    min-height: 100svh;
    min-width: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    &:has(#gjs) { // TODO: delete after layout refactor
      padding-bottom: 0;

      & > div {
        margin-top: 0 !important;
      }
    }
  }

  &__body {
    display: flex;
    flex: 1 0 auto;
    margin-top: 1.5rem;
  }
  
  &__footer {
    margin-top: 1.5rem;
  }

  &__error{
    background: $warning-lightest;
    border-top: 1px solid $warning;
    bottom: 0;
    right: 0;
    box-shadow: $box-shadow;
    display: none;
    padding: 0.75rem 1.25rem;
    position: fixed;
    width: calc(100% - $nav-menu-collapsed);
    z-index: $z-popover;

    &--nav-expanded {
      width: calc(100% - $nav-menu-expanded);
    }

    &-reload{
      cursor: pointer;
      font-weight: $font-weight-bold;
      color: $blue;
    }

    &-dismiss{
      cursor: pointer;
      float: right;
      font-weight: $font-weight-bold;
    }
  }
}

@include media-breakpoint-down(sm) {
  .main-body {
    display: block;
  }

  .layout {
    &__error {
      width: 100%;
    }
  }
}

.opt-out-body {
  display: flex;
  min-height: 100vh;
}
