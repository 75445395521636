.wizard-steps {
    padding: 0;
    list-style: none;
    width: 100%;
    display: grid;
    gap: 50px;
    grid-auto-flow: column;

    li {
        a {
            display: inline-block;
            width: 100%;
            padding-bottom: 10px;
            border-bottom: 3px solid currentColor;
            color: $gray-500;
            &.active {
                color: $secondary;
            }

            h3 {
                font-weight: 500;
                float: left;
                margin-bottom: 0;

                small {
                    font-weight: 600;
                }
            }
        }
    }
}