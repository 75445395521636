//
// Input and Textarea
// TODO: (1) - review absolute/relative length units after type system refactoring is merged
//

input, textarea {
    background-color: $white;
    border: $border-width solid $secondary-lightest;
    border-radius: $border-radius;
    color: $black;
    font-size: $font-size-base;
    height: $input-height;
    padding: 0.65em 1em; // TODO: (1)
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;

    &:where(textarea){
      height: auto
    }

    &:focus {
        border-color: $tertiary;
        outline: 0;
    }

    &::placeholder {
        color: $gray-600;
    }

    &[disabled] {
        cursor: not-allowed;
    }

    &:is(.input-icon input) {
        padding-left: calc(1.5em + 1.3em + 2px); // TODO: (1)
    }

    &:is(table input:not([type="checkbox"])) {
        height: 34px; // TODO: define some variables to all type of inputs height (checkbox, radio, etc.)
    }

    &:where(.input--search-icon){
        @include svg-bg($search-icon);
        background-repeat: no-repeat;
        background-position: center right 10px;
        background-size: 16px;
        padding-right: calc(10px * 2 + 16px);
    }
}

textarea {
    line-height: 1.5em;

    table & {
        padding: 7px 12px; // TODO: (1)
    }
}


