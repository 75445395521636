//
// Content
//

// Desktop Mode
@include media-breakpoint-up(lg) {
	.content {
		padding: get($page-padding, desktop) 0;
	}
}

// Tablet & Mobile Mode
@include media-breakpoint-down(md) {
	.content {
		padding: get($page-padding, tablet-and-mobile) 0;

		// Subheader Enabled Mode
		.subheader-enabled.subheader-transparent & {
			padding-top: 0;
		}
	}
}

.container-overview{
	.custom-row{
		svg{
			overflow: initial;
			g{
				path{
					stroke-linecap: round;
					stroke-width: 11px;
				}
			}
		}
		.apexcharts-datalabel-label{
			fill: black;
		}
	}
}