.grouped-typeahead {
  $self: &;
  position: relative;

  &__control {
    $control: &;
    align-items: center;
    background-color: $white;
    cursor: default;
    display: flex;
    justify-content: space-between;
    min-height: $input-height;
    position: relative;
    border-color: $secondary-light;
    border-radius: $border-radius;
    border-style: solid;
    border-width: $border-width;
    transition: $input-transition;
    outline: 0px;
    overflow: hidden;
    padding: $input-padding-y-sm $input-padding-x-sm;

    &--open {
      border-color: $primary;

      #{$self}__arrow-icon {
        transform: rotate(180deg);
      }
    }

    &-right {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      overflow: hidden;
      color: $black;
    }

    &-left {
      align-items: center;
      min-width: min-content;
      display: flex;
      gap: 0.25rem;
    }

    &-button {
      aspect-ratio: 1;
      height: calc(
        #{$input-height} - 2 * (#{$input-padding-y-sm} + #{$border-width})
      );
      cursor: pointer;
      background-color: transparent;
      border-color: transparent;
      border-radius: $border-radius-sm;
      color: $gray-500;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;

      &:hover,
      &:focus {
        background-color: $light;
        color: $secondary;
      }

      i {
        font-size: 1.5rem;
      }
    }
  }

  &__value {
    &-container {
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-placeholder {
      color: $gray-600;
    }
  }

  &__remove {
    &-button {
      height: 1.25rem;

      &:hover,
      &:focus {
        background-color: $danger-light;
        color: $danger;
      }
    }
    &-icon {
      font-size: 1.25rem;
    }
  }

  &__clear {
    &-button {
      &:hover,
      &:focus {
        background-color: $danger-light;
        color: $danger;
      }
    }
  }

  &__separator {
    height: calc(
      #{$input-height} - 2 * (#{$input-padding-y-sm} + #{$border-width})
    );
    border-right: 1px solid $gray-500;
    margin: 0;
  }

  &__content {
    bottom: 0;
    padding-bottom: 2rem;

    &--full-height {
      height: 100%;
      padding: 2rem 0;
      pointer-events: none;
    }
  }

  &__menu {
    min-width: 300px;
    background-color: $white;
    border-radius: $border-radius;
    border: 1px solid $gray-300;
    box-shadow: $box-shadow;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    pointer-events: all;

    &-header {
      padding: 0.5rem;
      position: relative;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $gray-300;
      border-radius: $border-radius;
      box-shadow: $box-shadow-sm;
    }

    &-body {
      padding: 0.5rem;
      padding-top: 1rem;
      height: 100%;
      overflow-y: auto;
    }

    &-loading,
    &-empty {
      padding: 0.5rem 1rem;
      font-weight: bold;
      text-align: center;
      font-size: $h4-font-size;
    }

    &-footer {
      padding: 0.5rem 1rem;
      border-top: 1px solid $gray-300;
      border-radius: $border-radius;
      box-shadow: $box-shadow-sm;
    }
  }

  &__search {
    &-input {
      padding-right: 2.5rem;
    }

    &-icon {
      position: absolute;
      right: 1.5rem;
      font-size: 1.5rem;
    }
  }

  &__group {
    &-header {
      padding: 0.5rem 1rem;
      border-radius: $border-radius;

      &:hover {
        background-color: $light;
      }

      &-title {
        font-weight: bold;
        text-transform: uppercase;
      }

      &-icon {
        margin-left: 0.5rem;
        font-size: 1.5rem;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &-item {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: $border-radius;

      &:hover {
        background-color: $light;
      }
    }
  }
}
