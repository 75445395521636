//
// InputDatePicker
//

.input-date-picker {
    position: relative;

    &__icon {
        font-size: 14px;
        line-height: 1;
        position: absolute;
        right: 12px; //TODO: (1)
        top: 50%;
        transform: translateY(-50%);
    }

    &__icon ~ &__input {
        padding-right: 38px;
    }

    @include icon-reset;
}
