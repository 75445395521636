//
// UserInfo
//

/*! purgecss start ignore */

.user-info {
  &__balloon-trigger {
    align-items: center;
    display: inline-flex;
  }

  .floater__trigger {
    &--active {
      background: $gray-100;
    }

    &:hover {
      background: $gray-100;
    }
  }

  &__balloon-trigger {
    border-radius: $border-radius;
    cursor: pointer;
    padding: 8px;
  }

  &__avatar {
    border-radius: 50%;
    height: 24px;
    overflow: hidden;
    width: 24px;
  }

  &__img {
    object-fit: cover;
    width: 100%;
  }

  &__name {
    font-weight: $font-weight-bold;
    margin-left: 8px; // TODO: (1)
  }
}

/*! purgecss end ignore */
