/*! purgecss start ignore */
.input-icon {
    position: relative;
    width: 100%;

    &--left {
        &__input-wrapper {
            & input {
                padding-left: 36px;
            }
        }
    }

    &--right {
        &__input-wrapper {
            & input {
                padding-right: 36px;
            }
        }
    }

    &__left {
        left: 1rem;
        line-height: 1;
        position: absolute;
        top: 1rem;
    }

    &__input-wrapper {
        width: 100%;
    }

    &__right {
        line-height: 1;
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
}
/*! purgecss end ignore */
