.page-header {
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: white;
  display: flex;
  border-radius: 0.42rem 0.42rem 0 0;
  gap: 2rem;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: 1px solid $gray-300;

  &__left {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  &__action {
    display: flex;
    gap: 1rem 2rem;
    justify-content: space-between;
    flex-direction: column;
  }

  &__title {
    font-weight: 600;
    font-size: 1.25rem;
    color: $black;
    margin: 0;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 1rem;
    color: $gray-500;
    margin: 0;
  }

  &__input-wrapper {
    max-width: 100%;
    flex-grow: 1;
  }

  //TODO: Delete after standardization of buttons
  .button {
    white-space: nowrap;
    font-size: 1.08rem;
    min-width: unset;
    padding: 8px 14px;
  }
}

@include media-breakpoint-up(sm) {
  .page-header {
    $self: &;

    &__action,
    &--small {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

@include media-breakpoint-up(lg) {
  .page-header {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    &__action {
      flex-wrap: nowrap;
      flex: 1;
      justify-content: end;
    }

    &__input-wrapper {
      max-width: 400px;
      min-width: 250px;
    }
  }
}
