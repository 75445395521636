//
// Toastr
//

$positions: top, bottom;
$alignments: full-width, center, left, right;
$states: (
  success: (
    color: $success,
    light: $success-light,
    icon: url("../media/icons/checkbox-circle-fill.svg"),
  ),
  info: (
    color: $info,
    light: $info-light,
    icon: url("../media/icons/error-warning-fill.svg"),
  ),
  warning: (
    color: $warning,
    light: $warning-light,
    icon: url("../media/icons/alert-fill.svg"),
  ),
  error: (
    color: $danger,
    light: $danger-light,
    icon: url("../media/icons/close-circle-fill.svg"),
  ),
);

//purgecss start ignore
#toast-container {
  box-sizing: border-box;
  position: fixed;
  z-index: $z-notify-message; // TODO: update z-index when all components have consistent z-index
  width: 300px;
  max-width: 100%;
  pointer-events: none;

  @each $position in $positions {
    @each $alignment in $alignments {
      &.toast-#{$position}-#{$alignment} {
        #{$position}: 12px;

        @if $alignment == center {
          right: 50%;
          transform: translateX(50%);
        } @else if $alignment == full-width {
          right: 50%;
          transform: translateX(50%);
          width: 96%;
        } @else {
          #{$alignment}: 12px;
        }
      }
    }
  }
}

// Base
.toast {
  $self: &;
  overflow: hidden;
  transition: $transition;
  border: 1px solid $light;
  background-color: $light;
  box-shadow: $box-shadow;
  color: $black;
  margin: 0;
  border-radius: $border-radius;
  padding: 1rem;
  padding-left: 4rem;
  min-height: 3.5rem;
  width: 100%;
  max-width: none;
  opacity: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: auto;

  &:hover {
    border-color: $dark;
  }

  // Icon
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    width: 2rem;
    aspect-ratio: 1;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  // States
  @each $state, $props in $states {
    &.toast-#{$state} {
      background-color: map-get($props, light);
      border-color: map-get($props, light);

      &:hover {
        border-color: map-get($props, color);
      }

      &:after {
        mask-image: map-get($props, icon);
        background-color: map-get($props, color);
      }
    }
  }

  // Title
  &-title {
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 0.25rem;
  }

  // Message
  &-message {
    font-size: 1rem;
    word-wrap: break-word;
  }

  // Close button
  &-close-button {
    outline: none;
    position: absolute;
    background: transparent;
    border: none;
    right: 0;
    top: 0;
    font-size: 20px;
    opacity: 0.6;
    line-height: 1;
    cursor: pointer;

    &:hover,
    &:focus-visible {
      text-decoration: none;
      opacity: 1;
    }

    :where(button) {
      padding: 0;
      cursor: pointer;
      background: transparent;
      border: 0;
      -webkit-appearance: none;
    }
  }

  &-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    background-color: rgba($dark, 0.4);
  }
}
//purgecss end ignore
