// Stylesheet for copyright "login with google" button //

.google-btn {
  $white: #fff;
  $google-blue: #4285f4;
  $google-shadow: rgba(0, 0, 0, 0.25);
  $google-active-blue: #3367d6;
  $google-hover-shadow: rgba(66, 133, 244, 0.3);
  border: 1px solid $google-blue;
  background-color: $google-blue;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 $google-shadow;
  display: inline-flex;
  align-items: center;

  &__icon-wrapper {
    border-radius: 2px 0 0 2px;
    background-color: $white;
    padding: 10px;
  }

  &__icon {
    width: 18px;
    height: 18px;
  }

  &__text {
    color: $white;
    font-size: 14px;
    font-family: "Roboto";
    padding: 0 15px;
  }

  &:hover {
    box-shadow: 0 0 3px 3px $google-hover-shadow;
    cursor: pointer;
  }

  &:active {
    background: $google-active-blue;
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);
