//
// Tags
//

/*! purgecss start ignore */
.pill {
    $self: &;
    background-color: $light;
    border-radius: $rounded-pill;
    border: 1px solid $gray-600;
    color: $black;
    display: inline-block;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    line-height: 1.4;
    padding: 4px 8px;
    white-space: nowrap;

    &--rect {
        border-radius: 0;
    }

    @each $name, $color in $theme-colors {
        &--#{$name} {
            background-color: theme-light-color($name);
            border-color: $color;
        }
    }

    &--bg-default {
        background-color: $light;
    }
}
/*! purgecss end ignore */
