//
// word-break property-related utility classes
//

.word-break-normal {
    word-break: normal;
}

.word-break-all {
    word-break: break-all;
}

.word-break-keep {
    word-break: keep-all;
}
