input:where([type="radio"]) {
  appearance: none;
  border: none;
  background: $gray-200;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 18px;
  height: 1em;
  line-height: 1em;
  position: relative;
  width: 1em;

  &:disabled {
    pointer-events: none;
  }

  &:after {
    border-radius: 50%;
    content: "";
    height: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: scale(0);
    translate: -50% -50%;
    transition: 120ms all ease-in-out;
    visibility: visible;
    width: 50%;
  }

  &:hover {
    &:after {
      background: $gray-500;
      transform: scale(1);
    }
  }

  &:checked {
    &:after {
      background: $primary;
      transform: scale(1);
    }
  }
}
