//
// Z-index
// All the component's z-index should be managed here
// We need to gradually add them as variables and set them here
//

$z-feedback-button: 10;
$z-sidebar: 20;
$z-typeahead-backdrop: 30;
$z-typeahead-control-active: 31;
$z-typeahead-menu: 32;
$z-modal-backdrop: 40;
$z-modal: 50;
$z-popover: 60;
$z-balloon: 70;
$z-tooltip: 80;
$z-notify-message: 90;
