//
// BalloonContent
//

.balloon-content {
  background-color: $white;
  border: $border-width solid $gray-200;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}
