.datetimepicker {
    box-sizing: border-box;
    clear: both;
    position: relative;
    text-align: left;
}

.datetimepicker-value-container {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    flex: 1 1 0%;
    width: 100%;
    border: 1px solid rgb(191, 191, 191);
    border-radius: .25rem;
    z-index: 1045;
}

.datetimepicker-clear-button,
.datetimepicker-calendar-button,
.datetimepicker-time-button {
    color: rgb(204, 204, 204);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border: 0 none transparent;
    height: calc(1.5em + .75rem + 2px);
    padding: 1px 4px;
}

.datetimepicker-clear-button:hover {
    color: rgb(222, 53, 11);
}

.datetimepicker-calendar-button:hover,
.datetimepicker-time-button:hover {
    color: rgb(130, 130, 130);
}

.datetimepicker-calendar-button,
.datetimepicker-time-button {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

.datetimepicker.is-datepicker-open .datetimepicker-calendar-button {
    color: rgb(38, 132, 255);
}

.datetimepicker.is-timepicker-open .datetimepicker-time-button {
    color: rgb(38, 132, 255);
}

.datetimepicker-input {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    border: 0 none transparent;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    padding: .375rem .75rem;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    position: relative;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0;
}

.datetimepicker-background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    position: fixed;
    background-color: transparent;
    z-index: 1040;
    cursor: pointer;
}

.datepicker-modal,
.timepicker-modal {
    top: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px;
    margin-bottom: 8px;
    margin-top: 2px;
    position: absolute;
    z-index: 1050;
    box-sizing: border-box;
    border: 1px solid rgb(191, 191, 191);
    border-radius: 4px;
}

.datepicker-table {
    display: table;
    margin: 0 auto;
}

.datepicker-header {
    display: table-header-group;
}

.datepicker-header-cell,
.datepicker-cell {
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    border-radius: 4px;
    padding: .45rem;
    color: rgb(74, 74, 74);
    text-decoration: none;
    border: 0 none transparent;
    background-color: transparent;
}

.datepicker-header-cell {
    color: rgb(122, 122, 122);
    font-weight: 600;
}

.datepicker-body {
    display: table-row-group;
}

.datepicker-row {
    display: table-row;
}

.datepicker-cell:not(.is-disabled),
.timepicker-cell:not(.is-disabled) {
    cursor: pointer;
}

.datepicker-cell:hover:not(.is-selected),
.timepicker-cell:hover:not(.is-selected) {
    background-color: rgb(222, 235, 255);
    color: rgb(10, 10, 10);
}

.datepicker-cell.is-today {
    border: 1px solid rgba(38, 132, 255, .5);
}

.datepicker-cell.is-secondary-month,
.datepicker-cell.is-disabled,
.timepicker-cell.is-disabled {
    color: rgb(181, 181, 181) !important;
}

.datepicker-cell.is-selected,
.timepicker-cell.is-selected {
    background-color: rgb(38, 132, 255);
    color: rgb(255, 255, 255) !important;
}

.datepicker-navigation {
    display: flex;
    justify-content: space-between;
}

.datepicker-previous-button,
.datepicker-next-button,
.datepicker-today-button {
    background-color: transparent;
    color: rgb(204, 204, 204);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 1px 3px;
    border: 0 none transparent;
    margin: .2rem .15rem;
}

.datepicker-today-button {
    color: rgb(74, 74, 74);
}

.datepicker-select-month,
.datepicker-input-year {
    color: rgb(74, 74, 74);
    display: flex;
    box-sizing: border-box;
    padding: 1px 3px;
    border: none;
    margin: .2rem .15rem;
    font-weight: 700;
    cursor: pointer;
}

.datepicker-select-month {
    width: 100px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    -moz-text-overflow: '';
    text-overflow: '';
}

.datepicker-input-year {
    width: 60px;
    -moz-appearance: textfield;
}

.datepicker-input-year::-webkit-outer-spin-button,
.datepicker-input-year::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.timepicker-modal {
    width: 150px;
    height: 230px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: .4rem;
}

.timepicker-cell {
    text-align: left;
    vertical-align: middle;
    padding: .15rem .6rem;
    color: rgb(74, 74, 74);
    text-decoration: none;
    border: 0 none transparent;
    background-color: transparent;
}
