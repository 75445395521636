//
// Checkbox
// :after pseudo element represents the check
// input itself represents the box of the input
// (has less border radius) than it's representation
// added :not(.switch-cb) to exclude YesNo checkbox style
// (this should be deleted as soon as YesNo checkbox is removed)

input:where([type="checkbox"]:not(.switch-cb)) {
  appearance: none;
  background: $gray-200;
  border: $border-width solid $gray-200;
  border-radius: 0.25em;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  height: 1em;
  line-height: 1em;
  position: relative;
  transition: 120ms all ease-in-out;
  width: 1em;

  &:after {
    border: 0.1em solid white;
    border-left-width: 0;
    border-top-width: 0;
    content: "";
    height: 0.5em;
    left: 50%;
    position: absolute;
    rotate: 45deg;
    top: 45%;
    transform: scale(0);
    transition: 120ms all ease-in-out;
    translate: -50% -50%;
    visibility: visible;
    width: 0.25em;
  }

  &:hover {
    border-color: $gray-500;
    cursor: pointer;
  }

  &:checked {
    background: $primary;
    border-color: transparent;

    &:after {
      transform: scale(1);
    }

    &:hover {
      background: $primary-hover;
    }
  }

  &:disabled {
    cursor: not-allowed;
    border-color: transparent;
    background: $gray-200;

    &:checked {
      background: $primary-lightest;
    }
  }
}
