//
// Tags
//

/*! purgecss start ignore */
.tag {
    $self: &;
    background-color: $light;
    border-radius: 4px;
    border: 1px solid $gray-600;
    color: $black;
    display: inline-flex;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    line-height: 1.4;
    gap: 3px;
    padding: 3px 6px;
    white-space: nowrap;

    &--draggable {
        cursor: grab;
    }

    @each $name, $color in $theme-colors {
        &--#{$name} {
            background-color: theme-light-color($name);
            border-color: $color;
        }
    }

    &--bg-default {
        background-color: $light;
    }
}
/*! purgecss end ignore */
