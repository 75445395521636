// MIXINS

@mixin rounded($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
}

@mixin shadow($x, $y, $blur, $color) {
  -webkit-box-shadow: $x $y $blur $color;
     -moz-box-shadow: $x $y $blur $color;
          box-shadow: $x $y $blur $color;
}

@mixin shadow-inset($x, $y, $blur, $color) {
  -webkit-box-shadow: inset $x $y $blur $color;
     -moz-box-shadow: inset $x $y $blur $color;
          box-shadow: inset $x $y $blur $color;
}

@mixin transition($property) {
  -webkit-transition: $property;
     -moz-transition: $property;
       -o-transition: $property;
          transition: $property;
}

@mixin transform($property) {
  -webkit-transform: $property;
     -moz-transform: $property;
       -o-transform: $property;
          transform: $property;
}

@mixin box-sizing {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

@mixin linear-gradient($from, $to) {
  /* Fallback for sad browsers */
  background-color: $to;
  /* Mozilla Firefox */
  background-image:-moz-linear-gradient($from, $to);
  /* Opera */
  background-image:-o-linear-gradient($from, $to);
  /* WebKit (Chrome 11+) */
  background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0, $from), color-stop(1, $to));
  /* WebKit (Safari 5.1+, Chrome 10+) */
  background-image: -webkit-linear-gradient($from, $to);
  /* IE10 */
  background-image: -ms-linear-gradient($from, $to);
  /* W3C */
  background-image: linear-gradient($from, $to);
}

@mixin background-image($size) {
  background-size: $size;
  background-repeat: no-repeat;
  background-position: center center;
}