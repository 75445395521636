.loading-text {
  span {
    display: inline-block;
    margin: 0;
    animation: loading 1.2s infinite alternate;
    @for $i from 1 through 6 {
      &:nth-child(#{$i + 1}) {
        animation-delay: #{$i * 0.2}s;
      }
    }
  }
}

@keyframes loading {
  0% {
    filter: blur(0);
    opacity: 1;
  }
  100% {
    filter: blur(0.05em);
    opacity: 0.2;
  }
}
