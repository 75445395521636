//
// List Group
//

.list-group-flush {
    // Flush Item
    > .list-group-item {
        &:last-child {
            border-bottom-width: 1px;
        }
    }
}

// Item
.list-group-item {
    padding: 8px 0;
}
