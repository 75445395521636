//
// Query Builder
//

// resets
.query-builder {
    .rule-container {
        .rule-operator-container,
        .rule-filter-container,
        .rule-value-container,
        .rule-value-container input, 
        .rule-value-container select {
            margin: unset;
        }
    }
}

// styles
.query-builder {
    width: 100%;

    .rules-group-container {
        background: $white;
        border-radius: $border-radius;
        border: $border-width solid $gray-200;
        margin: 0;
        padding: 0;
        width: 100%;

        .rules-group-container { // nested groups
            margin-top: 6px;
            padding: 12px;
        }

        &:first-of-type {
            border: none;
        }

        .btn-danger {
            background-color: $primary;
            border-color: $primary;

            &:hover {
                background-color: $primary;
                border-color: $primary;
            }

            &.btn-danger-secondary {
                margin-bottom: 8px;
            }
        }
    }

    .rules-group-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: unset;
    }

    .group-actions {
        align-items: center;
        display: inline-flex;

        &__delete-group {
            cursor: pointer;
            display: inline-block;
            margin-left: 16px;
        }
    }

    .rules-group-body {
        margin-top: 15px;
    }

    .rule-container {
        background: $gray-100;
        border: $border-width solid $gray-200;
        display: flex;
        gap: 6px;
        margin: 6px 0 0 0;
        padding: 6px;

        & .rule-value-container {
            align-items: center;
            display: flex;
            gap: 12px;
        }
    }

    .rule-heading {
        display: none;
    }

    .rule-filter-container {
        min-width: 0;
    }

    .rule-value-container {
        border-left: none;
        flex-grow: 1;
        min-width: 0;
        padding-left: 0;

        &:where(:not(:empty)) {
            margin-left: 6px;
        }

        input[type="text"],
        input[type="number"],
        select {
            padding: 0.65rem 1rem;
        }

        input[type="text"]:only-child {
            width: 100%;
        }
    }

    .rule-header {
        &:where(:not(:empty)) {
            margin-left: 6px;
        }
    }

    .rule-operator-container {
        min-width: 0;

        &:where(:not(:empty)) {
            margin-left: 6px;
        }
    }

    .group-heading {
        display: none;
    }

    .rules-list {
        padding-left: 13px;

        & > ::before,
        & > ::after {
            border-color: $gray-200;
            border-width: 0 0 1px 1px;
            left: -14px;
            width: 13px;
        }

        & > ::after {
            border-width: 0 0 0 1px;
        }

        & > :first-child {
            &::before {
                bottom: 50%;
                height: calc(50% + 27px);
                top: unset;
            }
        }
    }

    select.form-control:invalid {
        color: $gray-500;
    }

    option {
        color: $black;
    }

    .pull-right {
        float: right;
    }

    .btn {
        background: $white;
        border: $border-width solid $gray-200;
        color: inherit;
        font-size: $font-size-base;
        font-weight: $font-weight-bolder;
        padding: 6px;

        &:where(.group-conditions .btn) {
            padding: 6px 22px;
        }

        &:hover {
            background: $white;
            border: $border-width solid $orange;
            color: $orange;
        }

        &.active {
            background: $gray-200;
            border: $border-width solid $gray-200;
            color: $orange;
        }

        &.disabled {
            background: $gray-200;
            border-color: $gray-200;
            color: $gray-600;
        }
    }
}

.query-builder-secondary{
    .btn-primary{
        display: none;

        &.active{
            display: block;
            border-radius: 0.42rem !important;
        }
    }

    .btn-danger{
        display: none ;
    }

    .rule-header{
        display: none;
    }

    .rules-group-header{
        padding: 0 0 0 15px;
    }

    .rules-list>::before{
        display: none;
    }

    .rules-list>::after{
        display: none;
    }

    .group-actions{
        display: none;
    }
}