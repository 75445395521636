/*! purgecss start ignore */
.rz-dropdown,
.rz-multiselect {
    &-panel {
        max-width: 400px;
        width: auto !important;
    }

    &-items-wrapper {
        overflow-x: auto !important;
    }
}
/*! purgecss end ignore */
