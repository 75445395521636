//
// Style for the DT Custom Modal Component
//

.dt-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-modal;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(63, 66, 84, 0.7);

  &--open {
    display: block;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
  }
}

.dt-modal-dialog {
  position: relative;
  margin: 0.5rem;
  pointer-events: none;
}

.dt-modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  padding: 6rem 0;
  margin: auto;
}

.dt-modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.42rem;
  -webkit-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  outline: 0; 
  height: fit-content;
  max-height: 100%;
}

.dt-modal-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.5rem 1.75rem;
  border-bottom: 1px solid #EBEDF3;
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem; 
}

.dt-modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.75rem;
  overflow: auto;
}

.dt-modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1.5rem;
  border-top: 1px solid #EBEDF3;
  border-bottom-right-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem; 
}