/*! purgecss start ignore */
.range-slider-wrapper {
    position: relative;
    max-width: 100%;
    min-width: 150px;
    user-select: none;
    font-size: 12px;
    line-height: 140%;
    padding-top: 30px;
}

.range-slider {
    --control-size: 16px;
    position: relative;
    width: 100%;
    user-select: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 5px;
}
.range-slider__header {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
}
.range-slider__tooltip {
    --anchor-point-x: 0;
    --anchor-point-y: 0;
    height: 0;
    width: 0;
    overflow: visible;
    position: absolute;
    left: calc(var(--control-size) * var(--anchor-point-x) + var(--position));
    top: calc(var(--control-size) * (var(--anchor-point-y) + 0.5));
}
.range-slider__tooltip-content {
    background: $light;
    border: 1px solid $gray-200;
    border-radius: 6px;
    position: absolute;
    padding: 4px 6px;
    color: #456cc0;
    width: max-content;
    top: 0;
    left: 0;
    transform: translate(
        calc(-50% - var(--translate-point-x) * 100%),
        calc(-50% - var(--translate-point-y) * 100%)
    );
}

.range-slider__body {
    height: var(--control-size);
    width: 100%;
    position: relative;
}
.range-slider__track {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: #ccc;
}
.range-slider__thumb:active {
    background: $light;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
}
.range-slider__thumb {
    position: absolute;
    aspect-ratio: 1/1;
    width: var(--control-size);
    background-color: #fff;
    transform: translate(-50%, -50%);
    top: +50%;
    left: var(--position);
    border-radius: 50%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
}
.range-slider__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/*! purgecss end ignore */
