//
//  Feedback Button
//


@keyframes fdbuttonmessage {
    from {opacity: 0; bottom: 50px;}
    to {opacity: 1; bottom: 60px;}
  }
  @keyframes fdbutton {
    from {opacity: 0; bottom: 10px;}
    to {opacity: 1; bottom: 20px;}
  }

.feedback-button {
    animation-name: fdbutton;
    animation-duration: 0.4s;
    animation-delay: 2s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    background-color: $orange;
    border-radius: 50%;
    bottom: 16px;
    color: $white;
    font-size: 18px;
    height: 36px;
    line-height: 36px;
    opacity: 0;
    position: fixed;
    right: 20px;
    text-align: center;
    width: 36px;
    z-index: $z-feedback-button;

    &__balloon {
        height: 100%;

        &-trigger {
            font-size: 20px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    

    &__header-logo {
        background: $secondary;
        border-radius: 50%;
        color: $white;
        display: inline-block;
        font-size: 19px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        width: 36px;
    }

    &__floater {
        min-width: 360px;
    }

    @include icon-reset;


}