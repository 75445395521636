.weekday-picker {
    $self: &;
    align-items: center;
    display: flex;
    justify-content: space-between;

    &__option {
        background: $white;
        border: $border-width solid $gray-200;
        border-radius: 50%;
        color: $gray-500;
        height: 30px;
        line-height: 30px;
        text-align: center;
        width: 30px;

        &:not(#{&}--disabled):not(#{&}--active):hover {
            color: $gray-600;
            border-color: currentColor;
            cursor: pointer;
        }

        &--active {
            background: $primary;
            border-color: transparent;
            color: $white;
        }
        
        &--disabled {
            background-color: $gray-200;
            color: $gray-500;
            cursor: not-allowed;
        }

        &--active#{&}--disabled {
            background-color: $primary-lightest;
        }
    }
}