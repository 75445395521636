.formatted-text-area {
    &--single-line {
        position: relative;
    }

    &__text-input-area {
        border-radius: 0;
        position: relative;
        z-index: 2;
        min-height: 155px;
        resize: none;
    }

    &__text-input-area-div {
        border-radius: 0;
        position: relative;
        z-index: 2;
        min-height: 155px;

        &[placeholder]:empty:before {
            content: attr(placeholder);
            color: #555;
            opacity: 0.4;
        }

        &[placeholder]:empty:focus:before {
            content: "";
        }
    }

    &__text-input-area-div-single-line {
        border-radius: 0;
        position: relative;
        z-index: 2;

        &[placeholder]:empty:before {
            content: attr(placeholder);
            color: #555;
            opacity: 0.4;
        }

        &[placeholder]:empty:focus:before {
            content: "";
        }
    }

    &__header {
        display: flex;
        border: 1px solid $gray-300;
        border-bottom: 0;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        padding: 8px 12px;

        .formatted-text-area__header-buttons {
            margin-right: 15px !important;
        }
    }

    &__header-single-line {
        align-items: center;
        border: 1px solid $gray-300;
        display: flex;
        gap: 6px;
        height: 100%;
        padding: 0 6px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        z-index: 3;
    }
}
