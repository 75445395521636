//
// Variables for shapes
//

$shape-sizes: (
    "xxs": 4px,
	"xs":  8px,
    "s":   12px,
    "m":   16px,
    "l":   24px,
    "xl":  32px,
    "xxl": 48px,
    "3xl": 115px
);
