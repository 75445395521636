.search-input {
  display: flex;

  &__input {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
      border-color: $primary;
    }
  }

  &__button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__icon {
    font-size: 1.5rem;
  }
}
