//
//  Analytics Framework Animations
//

@-webkit-keyframes afe-tile {
    0%{
        stroke-dasharray: 0.1 40;
    }
    100%{
        stroke-dasharray: 25 40;
    }
  }
  
  .afe-icon-tile {
      svg {
          path {
              -webkit-animation: afe-tile 1000ms linear infinite alternate;
  
              &:nth-of-type(1) {
                   -webkit-animation-delay:0s;
              }
              &:nth-of-type(2) {
                   -webkit-animation-delay:0.3s;
              }
              &:nth-of-type(3) {
                   -webkit-animation-delay:0.1s;
              }
          }
      }
  }
  
  
  
  
  @-webkit-keyframes afe-bars {
    0%{
        height: 0;
        transform: translateY(25px);
    }
    100%{
        height: 25;
        transform: translateY(0px);
    }
  }
  
  .afe-icon-bars {
      svg {
          rect {
              &:nth-of-type(1) {
                  -webkit-animation: afe-bars 1000ms linear infinite alternate;
                   -webkit-animation-delay:0.0s;
              }
              &:nth-of-type(2) {
                  -webkit-animation: afe-bars 1000ms linear infinite alternate;
                   -webkit-animation-delay:0.6s;
              }
              &:nth-of-type(3) {
                  -webkit-animation: afe-bars 1000ms linear infinite alternate;
                   -webkit-animation-delay:0.1s;
              }
              &:nth-of-type(4) {
                  -webkit-animation: afe-bars 1000ms linear infinite alternate;
                   -webkit-animation-delay:0.4s;
              }
          }
      }
  }
  
  
  @-webkit-keyframes afe-table {
    0%{
        opacity: 0.3;
    }
    100%{
        opacity: 1;
    }
  }
  
  .afe-icon-table {
      svg {
          rect {
              -webkit-animation: afe-table 600ms linear infinite alternate;
  
              &:nth-of-type(1) {
                   -webkit-animation-delay:0s;
              }
              &:nth-of-type(2) {
                   -webkit-animation-delay:0.3s;
              }
              &:nth-of-type(3) {
                   -webkit-animation-delay:0.1s;
              }
              &:nth-of-type(4) {
                   -webkit-animation-delay:0.1s;
              }
          }
      }
  }
  
  
  
  @-webkit-keyframes afe-image {
    0%{
        transform: translateX(-50%) translateY(50%);
    }
    100%{
        transform: translateX(100%) translateY(-30%);
    }
  }
  @-webkit-keyframes afe-image-rotate {
    0%{
        transform: rotate(-30deg) translateY(5%);
    }
    100%{
        transform: rotate(60deg) translateY(5%) ;
    }
  }
  
  .afe-icon-image {
      svg {
          g {
              -webkit-animation: afe-image-rotate 2000ms linear infinite alternate;
              transform-origin: 50% 50%;
  
          }
          path {
              &#circle {
              }
              
          }
      }
  }
  
  @-webkit-keyframes afe-text {
    0%{
        opacity: 0.3;
    }
    100%{
        opacity: 1;
    }
  }
  
  .afe-icon-text {
      svg {
          g {
  
          }
          path {
              &:nth-of-type(2) {
                  -webkit-animation: afe-text 600ms linear infinite alternate;
                  
              }
              
          }
      }
  }