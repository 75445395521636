// 
// Blankslate
//

.blankslate {
    padding: 12px 16px;
    text-align: center;

    &__icon {
        color: $gray-400;
        font-size: 32px;
        
        @include icon-reset;
    }

    &__message {
        color: $gray-600;
        font-size: 12px;
        margin-top: 10px;
    }
}