//
// Scrollbar
//

/*! purgecss start ignore */

* {
    @include scrollbar(
        $size: 8px,
        $foreground-color: $gray-400,
        $background-color: $gray-100
    )
}

.scrollbar-inside {
    @include scrollbar-inside;
}

/*! purgecss end ignore */
